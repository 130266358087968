import React from 'react'
import {  Tab, Button, Icon, Modal, Form, Message } from 'semantic-ui-react'
import {ModelForm} from './models.js'
import {formatDateTime, formatSize} from './func.js'
import {uploadFile, downloadFile} from './files.js'

const fileIcons=(e)=>{
    return (<Icon.Group key={'icon_'+e.guid}>
            <Icon name='disk' color='blue'/>
            {e.locked && <Icon name='lock' color='red' corner/>}
    </Icon.Group>)
}

const Files=(props)=>{
    const [updateElem, updateForm] = React.useState(0) 
    var { client, isServer, volume, ShowError} = props
    if (!volume) return null
    var loading=false
    const model={        
            name:'file',
            cols: 'name, modified, size',
            extcols: 'locked',
            edit: 'name,locked',
            order: 'name',
            filter: {field: 'volume', value: volume || null},
            fields: {
                name: ['Имя', 10],
                locked: ['Блокировать', 5, 'checkbox'],
                modified: ['Изменен', 4],
                size: ['Размер', 2],
            }, 
            disableAppend: true,
            row_icon: fileIcons,
            icon: 'disk',
            updateElem: volume+updateElem,
            commands: [],
            //form: ()=> {return null},        
            //onSave: onSaveDatabase,
            calc: {size: formatSize, modified: formatDateTime },
            //hiddenRows: (hiddenRow.use) ? hiddenRow.rows : []
        }
    
    const doneUpload=(data)=>{ 
        if (!data.result) return ShowError(data.error)
        updateForm(String(new Date())) 
            
    }
    
    const uploadClick=(data, guid)=>{
        //console.log(data)
        ShowError(null)
        if (data.name==='upload')
            uploadFile({volume: volume, guid:null, session: props.connector.guid, changeProgress: props.changeProgress, callback: doneUpload})
        if (data.name==='update')
            uploadFile({volume: volume, guid:guid, session: props.connector.guid, changeProgress: props.changeProgress, callback: doneUpload})
        if (data.name==='download') downloadFile({guid:guid, session: props.connector.guid, changeProgress: props.changeProgress})
    }
    
    if ((client && !isServer) || (!client && isServer)){
        model['commands'].push({name: 'upload', text: 'Добавить', icon:'add', color: 'blue', all: true, handle: uploadClick, row: true})
        model['commands'].push({name: 'update', text: 'Обновить', icon:'upload', color: 'green', all: false, handle: uploadClick, row: true})   
        model['commands'].push({name: 'download', text: 'Скачать', icon:'download', color: 'blue', all: false, handle: uploadClick})
    }

    //(client && isServer)    
    return <ModelForm {...props} model={model} loading={loading}/>    
        
}



const EditVolume=(props)=>{
	const [data, setData] = React.useState({})    
    const [isChanged, setChanged] = React.useState(false)
    const [error, ShowError] = React.useState(null) 
	const {open, close, save, loading, isServer, connector}=props
	
    const {model} = props
    const model_icon = model.icon || 'sticky note outline'
    
    const filter = {} 
    if (model.filter) filter[model.filter.field] = model.filter.value
    
    React.useEffect(() => { 
        return () => { connector.listen['EditVolume'] = null }	
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    React.useEffect(() => {setData({...props.data, limit: Math.trunc(props.data.limit/(1024*1024))}); setChanged(false)}, [props.data])
    
    connector.listen['EditVolume'] = (data)=>{
        if (data.action==='model_account_set')
            ShowError((!data.result) ? data.error : null)
    }
    
    const handleChange = (e, {name, value }) => {
        setData(p=>{ return{ ...p, [name]: value || null}})
        setChanged(true)
    }
	const handleChangeCheckbox = (e, {name, checked }) => {
        setData(p=>{return{ ...p, [name]: checked }})
        setChanged(true)
    }
    
    const closeForm=()=>{ShowError(null); close()}
    
    const client_name = ((isServer && props.extData) ? " ("+props.extData.client_name+")" : "") + ( (isChanged) ? '*' : '' )
    
    
    const files = <Files {...props} ShowError={ShowError} changeProgress={model.changeProgress} 
            volume={data.guid} client={data.client} loading={loading}/>
    
    const readOnly = (data.size && data.size>0) ? true : false
    const editFields = (
        <Form loading={loading} style={{height: '100%'}}>
            <Form.Group>
                <Form.Input width={8} label='Имя' name='name' value={data.name || ''} onChange={handleChange} />  
                <Form.Dropdown width={4} label='Тип' name='type' value={data.type || 0} onChange={handleChange} readOnly={readOnly}
                    selection options={[{value:0, text:'Локальный', icon:'disk'}, {value:1, text:'Samba', icon:'server'}]}/>                   
                <Form.Checkbox width={4} toggle label='Имена на диске' fitted style={{marginTop: '2em'}}
                    name='real_name' checked={data.real_name===true}  
                    onChange={handleChangeCheckbox} disabled={readOnly}/>
            </Form.Group>
            {data.type===1 && <Form.Group>
                <Form.Input width={10} label='Адрес' name='address' value={data.address || ''} onChange={handleChange}/>
                <Form.Dropdown width={6} label='Аккаунт' name='account' value={data.account || null} onChange={handleChange}
                    selection clearable options={model.options.optionsAccount}/>
            </Form.Group>}
            <Form.Group>
                {<Form.Input width={4} label='Ресурс' name='share' value={data.share || ''} onChange={handleChange} readOnly={readOnly}/>}
                <Form.Input width={(data.type===1)?9:13} label='Путь' name='path' value={data.path || ''} onChange={handleChange} readOnly={readOnly}/>
                <Form.Input width={3} type='number' label='Лимит (Мб)' name='limit' value={data.limit || 0} onChange={handleChange}/>                  
            </Form.Group>
        </Form>)
    
    const panes = [
      { menuItem: {key: 'list', content: 'Файлы',  icon:'list'}, render: () => <Tab.Pane style={{height:"95%"}}>{files}</Tab.Pane> },
      { menuItem: {key: 'setting', content: 'Настройки',  icon:'setting'}, render: () => <Tab.Pane style={{height:"95%"}}>{editFields}</Tab.Pane> },
    ]
    
    return (
        <Modal
          onClose={closeForm}
          open={open}
          closeOnDimmerClick={false}
          style={{height: '90%'}}
        >
          <Modal.Header><Icon name={model_icon}/>{' '} {data.name}{client_name}</Modal.Header>
          {error && <Message attached='bottom' error content={error}/>}
          <Modal.Content style={{height: '90%'}}>
            <Tab style={{height: '100%'}} panes={panes} />          
          </Modal.Content>          
          <Modal.Actions>            
            <Button color='grey' onClick={closeForm} icon='delete' content='Закрыть' disabled={loading}/>
            <Button positive  onClick={()=>{ ShowError(null); save({...data, ...filter, limit:data.limit*(1024*1024)}) }} icon='checkmark' content="Записать" disabled={loading || !isChanged}/>
          </Modal.Actions>          
        </Modal>
      )

}

const Volumes=(props)=>{
    const [options, setOptions] = React.useState({optionsAccount:[]})    
    var { client, client_guid, changeProgress, connector} = props
    var loading=false
    
    client_guid = client || client_guid
    
    React.useEffect(() => {        
        connector.send('model_account_list', {data:'name', order:'name', filter: {field:'client', value:client_guid}})
        return () => {
            connector.listen['volume_setting'] = null            
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
    
    connector.listen['volume_setting'] = (data)=>{        
        if (data.action==='model_account_list')  
                setOptions({...options, optionsAccount: data.data.map(e=>{ return {key:e.guid, text:e.name, value: e.guid }})})
    }

    const model={        
        name:'volume',
        cols: 'name'+((!props.hideClient) ? ',client_name' : '')+',size,limit',
        //extcols: 'account, port, db_name, scheduled_jobs_deny, sessions_deny, url',
        join: {client: [['name', 'client_name']]},
        edit: 'name,path',
        order: 'name',
        filter: {field: 'client', value: client_guid},
        fields: {
            name: ['Имя', 6],
            path: ['Путь', 4],
            client_name: ['Клиент', 6],
            size: ['Размер', 2],
            limit: ['Лимит', 2],
        },        
        disableAppend: false,
        row_icon: 'disk',
        icon: 'disk',
        //icons: icons,        
        updateElem: client,
        commands: [],
        form: EditVolume,        
        //onSave: onSaveDatabase,
        calc: {size: formatSize, limit: (v)=> {return (v) ? formatSize(v) : 'Нет'} },
        deep: false,
        changeProgress: changeProgress,
        //hiddenRows: (hiddenRow.use) ? hiddenRow.rows : [],
        options: options,
    }
    
return <ModelForm {...props} model={model} loading={loading}/>
        
}

export default Volumes;

