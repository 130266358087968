import { useState, useEffect, useRef } from 'react';

import { Loader, Dimmer } from 'semantic-ui-react'

import LoginForm from './login.js'
import connectorGui from './connector.js'
import MainForm from './main.js'
const crypto = require("crypto")

const FirstForm=()=>{
	const [appData, setAppData] = useState({first: true, app_type:'client'});
	const refConnector = useRef(null)
		
	useEffect(() => {
	  if(!refConnector.current || !appData.connected) 
		  refConnector.current = connectorGui(
				{onopen: onOpenConnector,
				 onclose: onCloseConnector})
          refConnector.current.listen['first'] = incommingMessage
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	
	const onOpenConnector=()=>{
		setAppData({ ...appData, connected: true })
		var session = sessionStorage.getItem('sessionId')
		refConnector.current.guid = session
		refConnector.current.send("first_load")
	}

	const incommingMessage=(data)=>{		
		if (!data){
			setAppData(p=>{return {...p, isAuth: false, first: false }})
			return
		}
		if (data.code===401 || data.code===403){
			const error = (data.action==='auth') ? data.error : ""
			setAppData(p=>{return {...p, isAuth: false, first: false, error: error }})
			//setAppData({ ...appData, isAuth: false } )
			return
		}
		if (data.code===200 && data.session){
			var isAuth = data.action!=='logout'
			if (data.session) {
				sessionStorage.setItem('sessionId', data.session)
				refConnector.current.guid = data.session				
			}
			setAppData(p=>{return {...p, 
								isAuth: isAuth, 
								first: false, 
								error: data.error, 
								sessionId: data.session,
								app_type: data.type || p.app_type,
								user: data.user || {},
                                client_guid: data.client
							}})
			return
		}
		setAppData(p=>{return {...p, first: false, error: data.error}})
			
	}

	const onCloseConnector=()=>{
		setAppData(p=>{return {...p,  first: false, connected: false }})
		setTimeout(()=>{
			refConnector.current = connectorGui(
				{onopen: onOpenConnector,
                onclose: onCloseConnector} )
            refConnector.current.listen['first'] = incommingMessage
		}, 1000)
	}
	
	
	

	const handleLogin=(_data)=>{
		const data = {..._data}
		data.password = crypto.createHash("md5").update(data.password || "").digest("hex")
		refConnector.current.send('auth', data)
	}
	
	const handleLogout=()=>{
		sessionStorage.setItem('sessionId', null)
		refConnector.current.listen['first'] = incommingMessage		
		refConnector.current.send('logout')
		setAppData(p=>{return {...p,  isAuth: false }})
	}
	
	const { first, isAuth, connected, error, sessionId, user, app_type, client_guid} = appData
	
	if(first) 
		return (<Dimmer active inverted>  <Loader inverted size='massive'/>		</Dimmer>)
	if (!isAuth)		 
		return <LoginForm disabled={!connected} onLogin={handleLogin} error={error}/>
	 
	return <MainForm 
				onLogout={handleLogout} 
				connector={refConnector.current} 
				sessionId={sessionId}
				connected = {connected}
				app_type = {app_type}
                client_guid = {client_guid}                
				user = {user}
			/>
}


export default FirstForm;