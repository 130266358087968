import React from 'react'
import {  Table, Icon, Input } from 'semantic-ui-react'


const OptionsForm=(props)=>{
	
	const [optionList, setOptionList] = React.useState([])
	const [nameEdit, setEdit] = React.useState(null)
	const [valueEdit, setValue] = React.useState(null)
	
	React.useEffect(() => {
		props.connector.send('options')
        return () => {
            props.connector.listen['options'] = null            
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	

	props.connector.listen['options'] = (data)=>{
		if (!data.result) 	return
		if (data.action==='options')
			setOptionList(data.data)			
		if (data.action==='option_set'){			
			setOptionList(data.data)
			setEdit(null)
		}
	}
	
	var {user} = props
	var {right} = user || 3
	
	const handleChange=(e, {name, value})=>{
		setValue(value)		
	}
	const handleClickCell=({target})=>{
		if(right!==1) return
		if (target.localName==='i'){
			props.connector.send('option_set', { data: {key: nameEdit, value: valueEdit} })
		}			
		if (target.localName==='td' || target.localName==='div'){
				const name = target.id.split("-")[1]
				if (name) setValue(optionList.find(e=> e.key===name).value) 
				setEdit(name)
		}
	}

	const rows=optionList.map((e,i)=>{	
		var styleEdit = {cursor: "pointer"}
		var cell = (<div onClick={handleClickCell}
					id={"optiondiv-"+e.key}
					style={{overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis'}}>
			{e.value}</div>)
		if (nameEdit===e.key){			
			styleEdit = {padding: 0}	
			var type="text"
			if (e.type==='int') type="number"
			cell = (<Input fluid type={type}
				icon={<Icon name='check' color='green' link />}
				onChange={handleChange}
				placeholder={e.key}
				name = {e.key}
				value={valueEdit}
			/>)
		}
		return ( <Table.Row key={i} guid={e.guid} positive={nameEdit===e.key}>
			<Table.Cell onClick={handleClickCell}
				style={{fontWeight: (nameEdit===e.key) ? "bold" : null}}>
				{e.description}
			</Table.Cell>
			<Table.Cell onClick={handleClickCell}
					style={styleEdit} id={"option-"+e.key} val_type={e.type}>
				{cell}
			</Table.Cell>
		</Table.Row>)
	})
	
	
	
	return (<div className='model list'  style={{height: '100%', overflowY:'auto'}}>
	<Table celled striped singleLine fixed>
		<Table.Header>
		  <Table.Row>
			<Table.HeaderCell width={5}>Параметр</Table.HeaderCell>
			<Table.HeaderCell width={11}>Значение</Table.HeaderCell>
		  </Table.Row>
		</Table.Header>
		<Table.Body>
			{rows}  
		</Table.Body>
    </Table>  
	</div>)
}

export default OptionsForm;