import React from 'react'
import { Table, Button, Icon, Modal, Form, Segment } from 'semantic-ui-react'
import {ContextMenu, CommandPanel} from "./contmenu.js";


const EditForm=(props)=>{
	const [data, setData] = React.useState({})
	const {open, close, save, loading}=props
	
    const {model} = props
    const form_fields = model.edit || ''
    const model_fields = model.fields || {}
    const model_icon = model.icon || 'sticky note outline'
    
    
    
    const filter = {} 
    if (model.filter) filter[model.filter.field] = model.filter.value
	
	React.useEffect(() => {setData({...props.data})}, [props.data])
	
	const handleChange = (e, {name, value }) => setData(p=>{return{ ...p, [name]: value || null }})
	const handleChangeCheckbox = (e, {name, checked }) => setData(p=>{return{ ...p, [name]: checked }})    
	const fields = form_fields.split(',').map(e=>{
        if (!e) return null
        const name = e.trim()        
        var res = model_fields[name]
        var label = res[0]
        //var width = res[1]
        var type = res[2] || ((name==='password') ? 'password' : 'text')
        var style = res[3] || {}
        if (type==='checkbox')
            return (<Form.Checkbox key={'model_'+name} label={label} style={style} 
					name={name} checked={data[name]===true} toggle 
					onChange={handleChangeCheckbox} />
                    )
        else if (type==='text-area')
            return (<Form.TextArea key={'model_'+name} label={label}  style={style}
					placeholder={res[0]} name={name} value={data[name] || ''}  
					onChange={handleChange} type={type} autoComplete='new-password'	/>
                    )
        else 
            return (<Form.Input key={'model_'+name} label={label}  style={style}
					placeholder={res[0]} name={name} value={data[name] || ''}  
					onChange={handleChange} type={type} autoComplete='new-password'	/>
                    )
    })

    const title=(typeof(model.title)==='function') ? model.title({...data}) : data.name
	return (
    <Modal
      onClose={close}
      open={open}
	  closeOnDimmerClick={false}
    >
      <Modal.Header><Icon name={model_icon}/>{' '} {title}</Modal.Header>
      <Modal.Content>
		<Form loading={loading}>
			{fields}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color='grey' onClick={close} icon='delete' content='Отмена' disabled={loading}/>
        <Button positive  onClick={()=>{ save({...data, ...filter}) }} icon='checkmark' content="Записать" disabled={loading}/>
      </Modal.Actions>
    </Modal>
  )
}


export const ModelForm=(props)=>{
    const [currentRow, setCurrentRow] = React.useState(null)
	const {model, connector, isAdmin, loading } = props    
    const {row_icon, filter, order, disableAppend, join, hideRow, extcols, calc, guidForm, onRowSelect, hideCommandPanel } = model
    const cols = model.cols || ''
    const fields = model.fields || {}
    const actionList = (model.customList) ? model.customList : `model_${model.name}_list` 
    
    const commands = model.commands || []
    
    const list_options = { data: cols + ((extcols) ? ','+extcols : ''), filter: filter, order: order, join: join,  label: guidForm}
    
    const ModelEditForm = model.form || EditForm
	
    const [list, setList] = React.useState([])
    const [modelLoading, setLoading] = React.useState(true)
    
	const [dataEdit, setEditForm] = React.useState({
				data: {}, extData:{}, open: false, 
                filter: filter,
				close: ()=> setEditForm(p=>{return{...p, open: false, loading:true}}),
				save: ()=>{}				
	})
	const [eventContextMenu, setEventContextMenu] = React.useState(null)
	
    const connectorName = `model_${model.name}`+((guidForm) ? '_guidForm' : '')
    
    React.useEffect(() => {
        return () => { connector.listen[connectorName] = null };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
	
    React.useEffect(() => {
        connector.send(actionList, list_options)        
        setLoading(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.model.name, props.model.updateElem, props.loading])
    
	const saveData=(data, extData)=>{		
		connector.send(`model_${model.name}_set`, { data: data,  label: guidForm, ext_data: extData} )
        setLoading(true)
        if (model.onSave) model.onSave(data)
	}
	
	connector.listen[connectorName] = (data)=>{	
		if (!data.result) return
        if (!( (data.label && guidForm===data.label) || (!guidForm && !data.label) )) return
        if (data.action==='delete_row')
			connector.send(actionList, list_options)
		if (data.action===actionList){            
			setList(data.data)            
            if (data.data && !data.data.find(r=>r.guid===currentRow))  setCurrentRow(null)
            setLoading(false)
            //Если нужен обратный возов при выдилении строки, то выделим первую
            if ( onRowSelect && !currentRow && data.data.length){
                setCurrentRow(data.data[0].guid)
                onRowSelect(data.data[0].guid, data.data[0])
            }
        }
        
		if (data.action===`model_${model.name}_get` && data.result)            
			setEditForm({...dataEdit, 
                open: true, 
                loading:false, 
                data: data[model.name] || {}, 
                extData: data.data || {}, 
                save: saveData
            })
		if (data.action===`model_${model.name}_set`){	
            setLoading(true)
			setEditForm({...dataEdit, open: false, loading:true})
            connector.send(actionList, list_options)
		}
	}
	
	
	
	const handleClick=(guid)=>{
		if (guid) connector.send(`model_${model.name}_get`, {guid: guid, deep: model.deep, label: guidForm})
			else setEditForm({...dataEdit, open: true, loading:false, data:{}, extData:{}, save: saveData})
        setCurrentRow(guid)
	}
    const onClickDivList=({target})=>{
        if (target.className==="model list") setCurrentRow(null)
    }

    var itemsMenu=[]
    if (!model.custom_commands) {
        itemsMenu=[{name:'edit'}, {name:'delete'}, {name:'reload', all: true}]
        if (!disableAppend) itemsMenu.unshift({name:'add', all: true}) 
    }
    commands.forEach(cmd=>{
        itemsMenu.push({name: cmd.name, text: cmd.text, all: cmd.all, icon: cmd.icon, color: cmd.color})
    })

    const showContextMenu=(e, guid)=>{
        if (!itemsMenu.length) return
        setCurrentRow(guid)
        e.preventDefault()
        e['guid'] = guid
        e['rowData'] = list.find(l=>l.guid===guid)
        setEventContextMenu(e)
    }
    const handleContextMenu = React.useCallback(() => setEventContextMenu(null), []);
    const onClickContextMenu = (e, cmd) =>{
        if (cmd==='add') handleClick(null)
        else if (cmd==='edit') handleClick(e.guid)
        else if (cmd==='reload') connector.send(actionList, list_options)
        else if (cmd==='delete') connector.send('delete_row', { data: model.name, guid: e.guid })
        else {
            const row = commands.find(c=> c.name===cmd)        
            if (row && row.handle) row.handle({ ...row , rowData: e.rowData}, e.guid)
        }
    }
    const onClickCommandPanel = (guid, name) =>{
        onClickContextMenu({rowData: list.find(l=>l.guid===guid) , guid:guid} , name)
    }

    const tabHeader = cols.split(',').map((e, i)=>{
        var [name, width] = fields[e.trim()]
        return <Table.HeaderCell width={width} key={e}>{name}</Table.HeaderCell>
    })
    
    const handleClickRow=(guid)=>{        
        if ( onRowSelect ){
            if (guid){
                setCurrentRow(guid)
                onRowSelect(guid, list.find(r=>r.guid===guid))
            }            
        }
        else setCurrentRow(guid)
    }

	const rows=list.map((e,i)=>{         
        if (hideRow && typeof(hideRow)==='function' && hideRow(e)) return null
        var icon = (typeof(row_icon)==='function') ? row_icon(e) : <Icon key="1" name={row_icon || 'sticky note outline'} color='blue'/>
        const cells=cols.split(',').map((c, i)=>{
            var text = e[c.trim()]
            var [name, width, type, style, cellStyle] = fields[c.trim()]
            if (calc && calc[c.trim()]) text = calc[c.trim()](text, e)
            else if (!i) text = <div style={{display:'flex'}}><div>{icon}</div><div className='td-text'>{text}</div></div> 
            //var is_str={typeof(text)==='string'}
            return (<Table.Cell key={c} style={cellStyle}>{text}</Table.Cell>)
        })
		return ( <Table.Row key={i} guid={e.guid} 
                        onDoubleClick={()=>handleClick(e.guid)} 
                        style={{cursor:"pointer", fontWeight: (currentRow===e.guid) ? 'bold' : 'normal'}}
                        positive={currentRow===e.guid}
                        onClick={()=> handleClickRow((currentRow===e.guid) ? null : e.guid)}
                        onContextMenu={ (ev)=>{ev['textRow']=e.name; showContextMenu(ev, e.guid)} }>
            {cells}
		</Table.Row>)
	})
	
	var {user} = props
	var {right} = user || 3
    
    if (props.hideList) return (dataEdit.open) ? <ModelEditForm { ...props } {...dataEdit } model={model} user={user} isAdmin={isAdmin || right===1} /> : null
	
	return (<Segment loading ={loading || modelLoading} basic 
            style={{padding:0, display: 'flex', flexDirection: 'column',   height: '100%'}}>
            {dataEdit.open && <ModelEditForm { ...props } {...dataEdit } model={model} user={user} isAdmin={isAdmin || right===1} />}
        {!hideCommandPanel && <CommandPanel items={itemsMenu} onClick={onClickCommandPanel} guid={currentRow}/>}
        <Segment className='model list' attached='bottom'
            style={{padding: 0, flexGrow: 1, overflowY:'auto'}}
            onClick={onClickDivList}>    
            <Table className='model' celled striped selectable fixed singleLine>
                <Table.Header onContextMenu={ (ev)=>{showContextMenu(ev, null)} } onClick={()=> handleClickRow(null)}>
                    <Table.Row>
                        {tabHeader}    		
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows}  
                </Table.Body>
            </Table>
        </Segment>
        <ContextMenu event={eventContextMenu} items={itemsMenu} open={eventContextMenu!==null} onClose={handleContextMenu} onClick={onClickContextMenu}/>  
	</Segment>)
}

