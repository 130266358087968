import React from 'react'
import { Button, Icon, Modal, Segment, Form, Message } from 'semantic-ui-react'
import {ModelForm} from './models.js'

const EditPort=(props)=>{
	const [data, setData] = React.useState({})    
	const {close, save, loading, isServer, error, model}=props
    const {options} = model
    
	
	React.useEffect(() => {
        setData({...props.data, client: model.filter.value})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])
	
	const handleChange = (e, {name, value }) => setData(p=>{return{ ...p, [name]: value }})
	//const handleChangeCheckbox = (e, {name, checked }) => setData(p=>{return{ ...p, [name]: checked }})
	
    const portSave=()=>{  save({...data, name: data.name || data.host+':'+data.port})  }
    
	return (
    <Modal onClose={close}  open={true}  closeOnDimmerClick={false} size='small'>
		<Modal.Header><Icon name='share alternate'/>{' '} {data.name}</Modal.Header>
		<Modal.Content>
			<Form loading={loading}>				
				<Form.Group>
					<Form.Input width={8} label='Хост' disabled={data.disabled || false} 
						placeholder='localhost' name='host' value={data.host || ''}  
						onChange={handleChange}/>
					<Form.Input width={4} label='Порт' type='number' disabled={data.disabled || false} 
						placeholder='localhost' name='port' value={data.port || ''}  
						onChange={handleChange}/>
					{ isServer && <Form.Input width={4} label='Служебный' type='number' disabled={data.disabled || false} 
						placeholder='localhost' name='lport' value={data.lport || ''}  
						onChange={handleChange}/> }
					</Form.Group>
				<Form.Group>
					<Form.Input width={16} label='Наименование'  
						placeholder='Локальный 3389' name='name' value={data.name || ''}  
						onChange={handleChange}/>
				</Form.Group>
				<Form.Group>
					<Form.Dropdown clearable selection width={8} label='Приложение'  
						placeholder='RDP' name='app' value={data.app || null}
                        options={options.optionsApp}
						onChange={handleChange}/>
                    <Form.Dropdown clearable selection width={8} label='Аккаунт'  
						placeholder='admin' name='account' value={data.account || null}
                        options={options.optionsAccount}
						onChange={handleChange}/>
				</Form.Group>
			</Form>
			{error && <Message error content={error}/>}
		</Modal.Content>
		<Modal.Actions>
			<Button color='grey' onClick={close} icon='delete' content='Отмена' disabled={loading}/>
			<Button positive  onClick={portSave} icon='checkmark' content="Записать" disabled={loading}/>
		</Modal.Actions>
    </Modal>
  )
}

const PortsForm=(props)=>{
    const [listTunnels, setListTunnels] = React.useState([])
    const [options, setOptions] = React.useState({optionsAccount:[], optionsApp:[]})
    var { connector, client, loading, clientName, connected} = props
    
    
    React.useEffect(() => {
        connector.send('model_application_list', {data:'name', order:'name', label: 'port'})        
        return () => { connector.listen['port_form'] = null };
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); 
    React.useEffect(() => {
        connector.send('model_account_list', {data:'name', order:'name', label: 'port', filter: {field: 'client', value: props.client || ''}})            
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.client]);     
    
    connector.listen['port_form'] = (data)=>{   
        if (data.action==='tunnels') setListTunnels(data.data.detail.filter(e=>e.client===client))
        if (data.tunnels) setListTunnels(data.tunnels.detail.filter(e=>e.client===client))
        if (data.action==='model_application_list' && data.label==='port')  
                setOptions({...options, optionsApp: data.data.map(e=>{ return {key:e.guid, text:e.name, value: e.guid }})})
        if (data.action==='model_account_list' && data.label==='port')  
                setOptions({...options, optionsAccount: data.data.map(e=>{ return {key:e.guid, text:e.name, value: e.guid }})})
    }
    
    const connectPort=(guid, checked)=>{
		var connect_data = {guid: guid, connect: checked}
        props.connector.send('port_connect', { client: client, port: connect_data})
	}
    
    const runApp=(guid)=>{
        props.connector.send('app', { model: 'port', guid: guid})
    }
    
    const connectRow=(t, row)=>{
        var is_open = listTunnels.find(e=> row.port===e.port &&  row.host===e.host)
        return (<Button size='mini' basic icon color={!is_open ? null : 'green'} disabled={!connected}              
                onClick={()=>connectPort(row.guid, !is_open)} >
             <Icon fitted name='exchange' color={!is_open ? 'grey' : 'blue'} style={{transform: !is_open ? null : 'scale(1.3)'}}/>
            </Button>)
    }
    
    const runAppButton=(t, row)=>{
        var is_open = listTunnels.find(e=> row.port===e.port &&  row.host===e.host)
        return (<Button size='mini' basic icon onClick={()=>runApp(row.guid)} disabled={!row.app || !connected || !is_open}>
                                <Icon name='play' color='green'/>
                            </Button>) 
    }
    
    const model={        
        name:'port',
        cols: 'status,name,run,address,lport',
        customList: 'port_list',
        //extcols: 'account, port, db_name, scheduled_jobs_deny, sessions_deny, url',
        //join: {client: [['name', 'client_name']]},
        //edit: 'name,path',
        order: 'name',
        filter: {field: 'client', value: client},
        fields: {
            status: ['', 2, null, null, {paddingTop:0, paddingBottom:0}],
            name: [clientName, 5],
            run: ['', 2, null, null, {paddingTop:0, paddingBottom:0}],
            address: ['Адрес', 5],
            lport: ['порт', 2],
        },        
        disableAppend: false,
        row_icon: ()=>null,
        icon: 'disk',
        //icons: icons,        
        updateElem: client,
        commands: [],
        hideCommandPanel: true,
        form: EditPort,        
        //onSave: onSaveDatabase,
        calc: {
            address: (e,row)=>row.host+':'+row.port, 
            status: connectRow, 
            run: runAppButton
        },
        deep: false,
        //onRowSelect: props.onClick,
        //changeProgress: changeProgress,
        //hiddenRows: (hiddenRow.use) ? hiddenRow.rows : [],
        options: options,
        guidForm: 'main_port_form_'+client
    }

    return <Segment raised style={{height: "100%"}}><ModelForm {...props} model={model} loading={loading}/></Segment>
}

export default PortsForm;