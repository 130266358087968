import {getWSURL} from './func.js'

function connectorGui({onopen, onclose, onerror}){ 
	const conn = { guid: null, checkStatus: null, listen: {}}
	   
	const ws = new WebSocket(getWSURL()+'/gui')
	
	ws.onmessage = function(event) {
		var data = JSON.parse(event.data)
        for (var key in conn.listen) 
            if (conn.listen[key])
                conn.listen[key](data)		
	};

	ws.onopen = function(event) {
		if(onopen){
			onopen()
		}
	};

	ws.onclose = function(event) {
		if(onclose){
			onclose()
		}
	};

	ws.onerror = function(event) {
		if(onerror){
			onerror()
		}
	};
	
	conn["send"]=(action, data=null)=>{
		var sending_data = {action: action, session: conn.guid}
		if (data) sending_data={...sending_data, ...data}
		ws.send(JSON.stringify(sending_data))
	}		
	conn.ws = ws
	return conn
}

export default connectorGui;