import React from 'react'
import {  Tab, Button, Icon, Modal, Form, Message } from 'semantic-ui-react'
import {DateTimeField} from './datetime.js'
import {ModelForm} from './models.js'
import {formatDateTime} from './func.js'

const successIcon=(row)=>{ 
    var name = 'close'
    var color = 'red'    
    if (row.success) {name = 'check'; color = 'green'}
    if (!row.end)  {name = 'play'; color = 'blue'}
    return <Icon key={'icon_row'+row.guid} name={name} color={color}/>
}
const dateLog=(v)=>{return (v) ? formatDateTime(v) : ''}

export const TaskLogs=(props)=>{
    var { task } = props
    
    if(!task) return null
    
    var loading=false
       const model={        
        name:'tasklog',
        cols: 'type,begin,end',
        extcols: 'type,value,datetime,success',
        join: {timing: [['type', 'type'], ['value', 'value'], ['datetime', 'datetime']]},
        edit: 'message',
        //order: 'name',
        filter: {field: 'task', value: task},
        fields: { 
            type: ['Событие', 8], 
            begin: ['Начало', 3],
            end: ['Окончание', 3],
            success: ['Результат', 2],
            message: ['Сообщение', 16, 'text-area', {height:'25em'}],
        },       
        disableAppend: true,
        row_icon: successIcon,
        icon: 'history',
        updateElem: task,
        commands: [],
        deep: false,
        calc: {type: formatRow, begin: dateLog, end: dateLog},
        title: (v)=>formatRow(v,v)
    }
        
return <ModelForm {...props} model={model} loading={loading}/>
}


const typeOptions=[
    {key: 1, value: 1, text: 'Однократно'},
    {key: 2, value: 2, text: 'Интервал'},
    {key: 3, value: 3, text: 'Каждый день'},
    {key: 4, value: 4, text: 'День недели'},
    {key: 5, value: 5, text: 'День месяца'},
]
const weekDays=[ 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье' ]
const weekDaysOptions=weekDays.map((e,i)=>{return {key:i, value:i, text:e}})

const formatRow=(val, row)=>{
    if (row.type===1) return <span>{"Однократно в "}<b>{formatDateTime(row.datetime)}</b></span>
    if (row.type===2) return <span>{"Через каждые "}<b>{row.value}</b>{" сек."}</span>
    if (row.type===3) return <span>{"Каждый день в "}<b>{formatDateTime(row.datetime).substr(-5)}</b></span>
    if (row.type===4) return <span><b>{(row.value>=0 ? weekDays[row.value] : '')}</b>{" в "}<b>{formatDateTime(row.datetime).substr(-5)}</b></span>
    if (row.type===5) return <span>{"Каждое "}<b>{row.value}</b>{"-е число месяца в "}<b>{formatDateTime(row.datetime).substr(-5)}</b></span>    
}


const EditTiming=(props)=>{
	const [data, setData] = React.useState({})    
    const [isChanged, setChanged] = React.useState(false)
    const [error, ShowError] = React.useState(null)
    
    const {open, close, save, loading } =props
	
    const {model} = props
    const model_icon = model.icon
    
    React.useEffect(() => {
        setChanged(props.data.type===undefined)
        setData({...props.data, type: props.data.type || 1, value: props.data.value || 900, task: model.filter.value});
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [props.data])
   
    const closeForm=()=>{ShowError(null); close()}
    const handleChange = (e, {name, value }) => { setData(p=>{ return{ ...p, [name]: value || null}}); setChanged(true)  }
    
    return (
        <Modal
          onClose={closeForm}
          open={open}
          closeOnDimmerClick={false}
          style={{top: '10%'}} size='tiny'
        >
          <Modal.Header><Icon name={model_icon}/>{' '} {formatRow(null,data)}</Modal.Header>
          {error && <Message attached='bottom' error content={error}/>}
          <Modal.Content>
               <Form loading={loading}>
                  <Form.Group >
                     <Form.Dropdown  width={16} label='Режим' name='type' value={data.type} onChange={handleChange}
                        selection options={typeOptions}/>
                  </Form.Group>
                  <Form.Group >
                  { (data.type===1) && <DateTimeField form label='Дата и время'  
                                        value={data.datetime} name='datetime' onChange={handleChange}/>}
                  
                  { (data.type===2) && <Form.Input  width={16} type='number' label="Секунды" name='value' 
                                        value={data.value} onChange={handleChange}/> }
                                        
                  { data.type===5 && <Form.Input  width={6} type='number' label="День месяца" name='value' 
                                        value={data.value>0 && data.value<32 ? data.value:1} onChange={handleChange}/> }
                  
                  { data.type===4 && <Form.Dropdown  width={10} label='День недели' name='value' value={data.value || 1} onChange={handleChange}
                                            selection options={weekDaysOptions}/> }
                  
                  { (data.type>=3) && <DateTimeField form timeOnly label='время'  
                                        value={data.datetime} name='datetime' onChange={handleChange}/>}
                  
                  </Form.Group>
               </Form>
          </Modal.Content>          
          <Modal.Actions>            
            <Button color='grey' onClick={closeForm} icon='delete' content='Закрыть' disabled={loading}/>
            <Button positive  onClick={()=>{ ShowError(null); save({...data}) }} icon='checkmark' content="Записать" disabled={loading || !isChanged}/>
          </Modal.Actions>          
        </Modal>
      )

}

export const Timing=(props)=>{
    var { task } = props
    
    if(!task) return null
    
    
    var loading=false
       const model={        
        name:'timing',
        cols: 'type',
        extcols: 'enabled,value,datetime',
        //join: {client: [['name', 'client_name']]},
        edit: '',
        //order: 'name',
        filter: {field: 'task', value: task},
        fields: { type: ['Расписание', 8] },       
        disableAppend: false,
        row_icon: 'clock outline',
        icon: 'clock',
        //icons: icons,        
        updateElem: task,
        commands: [],
        form: EditTiming,        
        //onSave: onSaveDatabase,
        calc: {type: formatRow},
        deep: false,
        //hiddenRows: (hiddenRow.use) ? hiddenRow.rows : [],
        //options: options,
    }
        
return <ModelForm {...props} model={model} loading={loading}/>
        
}



const EditTask=(props)=>{
	const [data, setData] = React.useState({})    
    const [isChanged, setChanged] = React.useState(false)
    const [error, ShowError] = React.useState(null)
    
    const {open, close, save, loading, isServer, connector}=props
	
    const {model} = props
    const model_icon = model.icon
    
    const filter = {} 
    if (model.filter) filter[model.filter.field] = model.filter.value
    
    React.useEffect(() => { 
        return () => { connector.listen['EditTask'] = null }
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);    
    React.useEffect(() => {setData({...props.data}); setChanged(false)}, [props.data])
    
    const handleChange = (e, {name, value }) => {
        setData(p=>{ return{ ...p, [name]: value || null}})
        setChanged(true)
    }
	const handleChangeCheckbox = (e, {name, checked }) => {  setData(p=>{return{ ...p, [name]: checked }}); setChanged(true)  }
    
    const closeForm=()=>{ShowError(null); close()}
    
    const client_row = model.options.optionsClient.find(e=>e.value===data.client)
    const client_name = ((isServer && data.client) ? " ("+(client_row && client_row.text)+")" : "") + ( (isChanged) ? '*' : '' )
    
    
    const tab1 = (<Form loading={loading} style={{height: "100%"}}>
                  {isServer && <Form.Group>
                    <Form.Dropdown width={16} label='Клиент' name='client' value={data.client || null} onChange={handleChange}
                        selection options={model.options.optionsClient} disabled={props.data.client!==undefined}/>
                  </Form.Group>}
                <Form.Group>
                    <Form.Input width={12} label="Наименование" placeholder='Наименование' name='name' value={data.name || ''}  
                        onChange={handleChange} autoComplete='new-password'	/>
                    <Form.Checkbox width={4} toggle label='Активно' fitted style={{marginTop: '2em'}}
                        name='enabled' checked={data.enabled===true}  
                        onChange={handleChangeCheckbox}/>
                </Form.Group>                
               </Form>)
    const tab2 = <Timing  style={{height: "100%"}}{...props} task={data.guid}/>
    const tab3 = <TaskLogs  style={{height: "100%"}}{...props} task={data.guid}/>
    
    const panes = []
    panes.push({ menuItem: {key: 'setting', content: 'Настройки',  icon:'setting'}, render: () => <Tab.Pane style={{height:"95%"}}>{tab1}</Tab.Pane>})
    if (props.data.guid){
        panes.push({ menuItem: {key: 'timing', content: 'Расписание',  icon:'list'}, render: () => <Tab.Pane style={{height:"95%"}}>{tab2}</Tab.Pane>})
        panes.push({ menuItem: {key: 'history', content: 'Журнал',  icon:'history'}, render: () => <Tab.Pane style={{height:"95%"}}>{tab3}</Tab.Pane>})
    }
    
    
    return (
        <Modal
          onClose={closeForm}
          open={open}
          closeOnDimmerClick={false}
          style={{height: '90%'}}
        >
          <Modal.Header><Icon name={model_icon}/>{' '} {data.name}{client_name}</Modal.Header>
          {error && <Message attached='bottom' error content={error}/>}
          <Modal.Content style={{height: '90%'}}>
            <Tab style={{height: '100%'}} panes={panes} />   
            
          </Modal.Content>          
          <Modal.Actions>            
            <Button color='grey' onClick={closeForm} icon='delete' content='Закрыть' disabled={loading}/>
            <Button positive  onClick={()=>{ ShowError(null); save({...data, ...filter}) }} icon='checkmark' content="Записать" disabled={loading || !isChanged}/>
          </Modal.Actions>          
        </Modal>
      )

}

const taskIcon=(row)=>{ return <Icon key={'icon_row'+row.guid} name={'clock outline'} color={(row.enabled) ? 'green' : 'grey'}/>}

const Tasks=(props)=>{
    const [options, setOptions] = React.useState({optionsClient:[]})    
    var { client, client_guid, isServer, connector} = props
    var loading=false

    client_guid = client || client_guid

    React.useEffect(() => {        
        if (isServer) connector.send('model_client_list', {data:'name', order:'name'})
        return () => {
            connector.listen['tasks_setting'] = null            
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
    
    connector.listen['tasks_setting'] = (data)=>{        
        if (data.action==='model_client_list')  
                setOptions({...options, optionsClient: data.data.map(e=>{ return {key:e.guid, text:e.name, value: e.guid, icon:'server' }})})
    }

    const model={        
        name:'task',
        cols: 'name'+((!props.hideClient) ? ',client_name' : ''),
        extcols: 'enabled',
        join: {client: [['name', 'client_name']]},
        edit: 'name, enabled',
        order: 'name',
        filter: {field: 'client', value: client_guid},
        fields: {
            name: ['Имя', 8],
            client_name: ['Клиент', 8],
            enabled: ['Активно', 8, 'checkbox'],
        },        
        disableAppend: false,
        row_icon: taskIcon,
        icon: 'clock',
        //icons: icons,        
        updateElem: client,
        commands: [],
        form: EditTask,        
        //onSave: onSaveDatabase,
        //calc: {size: formatSize},
        deep: false,
        //hiddenRows: (hiddenRow.use) ? hiddenRow.rows : [],
        options: options,
    }
    
return <ModelForm {...props} model={model} loading={loading}/>
        
}

export default Tasks;