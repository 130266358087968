import React from 'react'
import { TransitionablePortal, Segment, Progress } from 'semantic-ui-react'
import {getHTTPURL} from './func.js'

export const UploadProgress=(props)=>{ 
    //const [uploads, setStatus] = React.useState([])
    const {uploads}=props
	const animation='slide left', duration=600
    
   // React.useEffect(() => {setStatus(props.uploads)}, [props.uploads])
    
    const rows = uploads.map(e=>{
        return (<Progress key={e.guid} value={Math.trunc(e.status.loaded/e.status.total*100)} total={100} indicating>
            <div style={{fontSize: '0.7em', fontWeight: '500'}}>{e.status.name}</div>
        </Progress>)
        //<div key={e.guid}>{e.status.name}: {e.status.loaded}</div>
    })
	return (
		<TransitionablePortal
			open={uploads.length!==0}
            transition={{ animation, duration }}
			closeOnDocumentClick={false}
            closeOnEscape={false}
            closeOnPortalMouseLeave={false}
          >
		  
		  <Segment 
			style={{ width: '15%',
                right: '0',
                position: 'fixed',
                top: '0',
                zIndex: 1000,
              }}>
              {rows}
			</Segment>
          </TransitionablePortal>)
}


const UploadFiles=(target, { session, volume, guid, callback, changeProgress})=>{
    var url = getHTTPURL()+"/upload/"+volume+"/"+(guid || "new")
	   
    var fd=new FormData();
    const names=[]
    for (var i=0; i<target.files.length; i++) {
        fd.append('files', target.files[i])
        names.push(target.files[i].name)
    }
    var xhr = new XMLHttpRequest();
    var strName = (target.files.length===1) ? target.files[0].name : "Файлов: "+target.files.length
    //xhr.upload['SetProgress']=this.SetProgress
    xhr.upload.onprogress = (e)=>{
        changeProgress({guid: names.join(), status: {name: strName, loaded: e.loaded, total: e.total, done: false}})
        //console.log('xhr.upload progress: ' + e.loaded + ' / ' + e.total + ' = ' + (Math.floor(e.loaded/e.total*1000)/10) + '%');
        //this.SetProgress(e.loaded, e.total)
    };

    xhr.onreadystatechange = ()=> {
            if(xhr.readyState === XMLHttpRequest.DONE) {
                //th.GetData();
                //console.log('done ')
                changeProgress({guid: names.join(), status: {name: strName, loaded:1, total: 1, done: true}})
                if (xhr.response) callback(xhr.response)
                    else callback({result:false, error: "Неизвестная ошибка"})
                
            };
    };

    xhr.open("POST", url, true);
    xhr.setRequestHeader("Session", session);
    xhr.send(fd);
    xhr.responseType = 'json'
    target.remove()
    
}

export const uploadFile=(options)=>{
    var input = document.createElement('input')
    input.hidden = true
    input.type='file'
    input.multiple = true
    input.addEventListener('change', ()=>UploadFiles(input, options))
    input.click()
}


const getFilename=(xhr)=>{
    if (xhr._filename) return xhr._filename
    var disposition = decodeURI(xhr.getResponseHeader('Content-Disposition')).replace("utf-8''", '');
    var filename=null
    if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) { 
          filename = matches[1].replace(/['"]/g, '');
        }
    }
    xhr._filename = filename
    return filename
}

export const downloadFile=({ session, guid, changeProgress })=>{
    
    var url = getHTTPURL()+"/download/"+guid
    
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader("Session", session);
    xhr.responseType = 'blob';
    xhr.onprogress = (e) => {
        var filename = getFilename(e.currentTarget)         
        changeProgress({guid: guid, status: {name: filename, loaded: e.loaded, total: e.total, done: false}})
    }
    xhr.onload = (e) => {
        var type = xhr.getResponseHeader('Content-Type');
              
        var blob = new Blob([xhr.response], { type: type });
        var URL = window.URL || window.webkitURL;
        var downloadUrl = URL.createObjectURL(blob);

        
        var filename = getFilename(xhr)
        if (filename) {
            // use HTML5 a[download] attribute to specify filename
            var a = document.createElement("a");
            // safari doesn't support this yet
            if (typeof a.download === 'undefined') {
                window.location = downloadUrl;
            } else {
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        } else {
            window.location = downloadUrl;
        }
        setTimeout(function () {  URL.revokeObjectURL(downloadUrl); }, 100); // cleanup        
        changeProgress({guid: guid, status: {name: filename, loaded: 1, total: 1, done: true}})
    }
    xhr.send('ok');
}