import React from "react";
import { Menu, Popup, Icon, Confirm, Segment, Button } from "semantic-ui-react";


function createContextFromEvent(e) {
    if(!e) return null
  const left = e.clientX
  const top = e.clientY
  const right = left + 1
  const bottom = top + 1

  return {
    getBoundingClientRect: () => ({
      left,
      top,
      right,
      bottom,

      height: 0,
      width: 0,
    }),
  }
}

const itemsMenu=(menu_items, guid)=>{
    if (menu_items)
       menu_items = menu_items.map(e=>{
        const disabled = !guid && !e.all
        const r = {name: e.name, text: e.text, icon: e.icon, color: e.color}
        if (e.name==='edit'){
            r.content = e.text || "Изменить"
            r.icon = <Icon name="edit" color='green'/>
        }
        else if (e.name==='add'){
            r.content = e.text || "Создать"
            r.icon = <Icon name="add" color='green'/>
        }
        else if (e.name==='delete'){
            r.content = e.text || "Удалить"
            r.icon = <Icon name="trash alternate" color='red'/>
        }
        else if (e.name==='reload'){
            r.content = e.text || "Обновить"
            r.icon = <Icon name="undo" color='blue'/>
        }
        else {
            r.content = e.text
            r.icon = null
        }
        r.key = e.name
        r.disabled = disabled
        if (e.icon) r.icon =  <Icon name={e.icon} color={e.color || null} disabled={disabled}/> 
        return r
    })
    else  menu_items = [
            { name: "add", key: "add", content: "Создать", icon: <Icon name="add" color='green'/> },
            { name: "edit", key: "edit", content: "Изменить", icon: <Icon name="edit" color='green'/> },
            { name: "delete", key: "delete", content: "Удалить", icon: <Icon name="trash alternate" color='red'/> },
            { name: "reload", key: "reload", content: "Обновить", icon: <Icon name="undo" color='blue'/> }
          ];
     
    return menu_items
}

export const CommandPanel=({items, onClick, guid})=>{
    
    const [commandName, setCommandName] = React.useState(null) 
    
    const menu_items = itemsMenu(items, guid)
    
    const handleClick = (e, {name})=>{
        if (name.indexOf('delete')===0){
          setCommandName(name)
          return
        }
        onClick(guid, name)
    }
    
    const handleConfirm = () => {
        onClick(guid, commandName)
        setCommandName(null)
    }
    
    const buttons = menu_items.map(e=>{
        const btn = (<Button name={e.name} onClick={handleClick}  disabled={e.disabled}>
                {e.icon}
            </Button>)
        return <Popup  key={e.name} content={e.content} trigger={btn} />
    })

    return (<React.Fragment>
            <Segment size='mini' attached='top' style={{padding: '1px'}}>
                <Button.Group  icon basic>{buttons}</Button.Group>
            </Segment>
    <Confirm style={{zIndex:2100}}
          open={commandName && true}
          onCancel={() => {setCommandName(null)}}
          onConfirm={handleConfirm}
          cancelButton='Отмена'
          confirmButton="Удалить"
          header='Удаление'
          content=<Segment basic>{'Вы действительно хотите удалить текущую запись?'}</Segment>         
          size='mini'
        />
    </React.Fragment>)
}


export const ContextMenu = ({event, items, open, onClose, onClick}) => {
  const [commandName, setCommandName] = React.useState(null)  
  
  //console.log(event)
  if (!event) return null
  var menu_items = itemsMenu(items || event.items,  event.guid)
  
  
  
  const handleClick = (e, {name})=>{
      if (name.indexOf('delete')===0){
          setCommandName(name)
          return
      }
      onClick(event, name)
      onClose()
  }
  
  const handleConfirm = () => {
      onClick(event, commandName)
      setCommandName(null)
      onClose()
      
  }
  
  const event_title = (event.textRow) ?   `'${event.textRow}'` : 'текущую запись'
  
  return (<React.Fragment>
      <Popup basic context={createContextFromEvent(event)} onClose={onClose} open={open && !commandName}>
        <Menu
          items={menu_items}
          secondary
          vertical
          onItemClick={handleClick}
        />
      </Popup>
      
      
      <Confirm style={{zIndex:2100}}
          open={commandName && true}
          onCancel={() => {setCommandName(null); onClose()}}
          onConfirm={handleConfirm}
          cancelButton='Отмена'
          confirmButton="Удалить"
          header='Удаление'
          content=<Segment basic>{'Вы действительно хотите удалить '}<span style={{fontWeight:900}}>{event_title}</span>{'?'}</Segment>         
          size='mini'
        />
      </React.Fragment>
  );
};

export default ContextMenu;