import React, { useState } from 'react'
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'

const LoginForm=({disabled, onLogin, error})=>{
  const [data, setData] = useState({login:"", password:""})
  
  const handleChange=(e, {name, value})=>{
	setData({ ...data, [name]: value })
  }
  
  return (<Grid textAlign='center' style={{ height: '100vh', }} verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 450 }}  >
	  <Segment raised>
		  <Header as='h2' color='blue' textAlign='center' disabled={disabled}>
			<Image src='/logo128.png' /> Nexum
		  </Header>
		  <Form size='large' >
			<Segment>
			  <Form.Input fluid icon='user' iconPosition='left' placeholder='user name' 
						disabled={disabled}
						onChange={handleChange}
						name='login'
						value={data.login}/>
			  <Form.Input
				fluid
				icon='lock'
				iconPosition='left'
				placeholder='Password'
				type='password'
				disabled={disabled}				
				onChange={handleChange}
				name='password'
				value={data.password}
			  />

			  <Button color='blue' fluid size='large' disabled={disabled} onClick={e=>onLogin(data)}>
				Login
			  </Button>
			</Segment>
		  </Form>
		  {error && <Message error>{error}</Message>}
	  </Segment>
    </Grid.Column>
  </Grid>)
}

export default LoginForm