export const getWSURL=()=>{
    var url = ""    
	const loc = document.location
	if (loc.host !=='localhost:3000'){
		url = ((loc.protocol==='https:') ? 'wss' : 'ws') +'://' +loc.host
    }
    else {        
        var port = ((new URL(document.location)).searchParams.get("port") || 8000)
        var host = ((new URL(document.location)).searchParams.get("host") || 'localhost')
        url = "ws://"+host+":"+port
    }
    return url
}

export const getHTTPURL=()=>{
    if (document.location.host !=='localhost:3000')
        return ""
    return "http://localhost:"+((new URL(document.location)).searchParams.get("port") || 8000)
}


export const formatSize=(value)=>{
        var bytes = require('bytes');
        return bytes.format(Number(value||0), {thousandsSeparator: '`', decimalPlaces: 1, unitSeparator: ' '});
    }

export function formatDateTime(date, s=false)
{
	let d=new Date(date)	
	return ("0" + d.getDate()).slice(-2) + "." 
		+ ("0"+(d.getMonth()+1)).slice(-2) + "." 
		+ d.getFullYear() + " " 
		+ ("0" + d.getHours()).slice(-2) + ":" 
		+ ("0" + d.getMinutes()).slice(-2) 
		+ ((s) ? ":" + ("0" + d.getSeconds()).slice(-2) : "");
}

export function formatDateMySQL(date)
{
	let d=new Date(date)	
	return d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2)
        + " " 
		+ ("0" + d.getHours()).slice(-2) + ":" 
		+ ("0" + d.getMinutes()).slice(-2)+ ":" 
		+ ("0" + d.getSeconds()).slice(-2);
}

export function getFIO(fio){
	fio = fio || ""
	return fio.replace(/(?<=\S+) (\S)\S* (\S)\S*/, " $1. $2.");

}

export function Num(N, z='-')
{
	const Num = new Intl.NumberFormat('ru',{style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2});
	N=Number(N)
	if (N===0) return '-'
	return Num.format(N);
}

export function formatDate(date) {
  date=new Date(date)	

  var dd = date.getDate();
  if (dd < 10) dd = '0' + dd;

  var mm = date.getMonth() + 1;
  if (mm < 10) mm = '0' + mm;

  var yy = date.getFullYear();

  return dd + '.' + mm + '.' + yy;
}

export function formatDateRU(date) {
  date=new Date(date)	

  return date.toLocaleString('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
}