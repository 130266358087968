import {  Input, Icon, Form } from 'semantic-ui-react'
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

export const DateTimeField=(props)=>{
    const value = props.value && new Date(props.value)    
    const setDate=(m)=>{
        if(props.onChange) props.onChange(m,{...props, value: m && m.format('YYYY-MM-DDTHH:mm:ss')} )
    }
    
    const renderInput=( inputProps, openCalendar, closeCalendar )=>{
        const clear=()=>{
            inputProps.onChange({target: {value: ''}});
        }
        if (props.form) return (<Form.Input  {...inputProps} readOnly label={props.label} width={props.width}
                icon={props.clear && <Icon name='delete' link onClick={clear}/>}
             disabled={props.disabled}/>)   
        else return (props.clear && <Input  {...inputProps} readOnly disabled={props.disabled}
                icon={<Icon name='delete' link onClick={clear}/>}
            />)
    }
    
    return <Datetime value={value} onChange={setDate} readOnly={props.readOnly}
               updateOnView={props.updateOnView || "time"} 
               dateFormat={props.timeOnly ? false : "DD.MM.YYYY"} 
               timeFormat={props.dateOnly ? false : "HH:mm"} 
               renderInput={ renderInput }/>
}