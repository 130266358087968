import './App.css';
import './modules/styles.css';
import 'semantic-ui-css/semantic.min.css'

import FirstForm from './modules/first.js';


function App() {
	return <FirstForm />;
}

export default App;
