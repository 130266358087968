import React from 'react'
import {  Tab, Button, Icon, Modal, Form, Message } from 'semantic-ui-react'
import {ModelForm} from './models.js'
import {formatSize} from './func.js'
import {Timing, TaskLogs} from './task.js'

const EditForm=(props)=>{
	const [data, setData] = React.useState({})    
    const [isChanged, setChanged] = React.useState(false)
    const [error, ShowError] = React.useState(null)
    
    const {open, close, save, loading }=props
    const {val_name, val_label}=props
	
    const {model} = props
    const model_icon = model.icon
    
    React.useEffect(() => {
        setChanged(props.data.type===undefined)
        setData({...props.data, task: model.filter.value}); 
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [props.data])
   
    const closeForm=()=>{ShowError(null); close()}
    const handleChange = (e, {name, value }) => { setData(p=>{ return{ ...p, [name]: value || null}}); setChanged(true)  }
    
    return (
        <Modal
          onClose={closeForm}
          open={open}
          closeOnDimmerClick={false}
          style={{top: '10%'}} size='tiny'
        >
          <Modal.Header><Icon name={model_icon}/>{' '} {''}</Modal.Header>
          {error && <Message attached='bottom' error content={error}/>}
          <Modal.Content>
               <Form loading={loading}>
                    <Form.Dropdown  width={16} label={val_label} name={val_name} value={data[val_name] || null} onChange={handleChange}
                        selection options={model.options}/>
               </Form>
          </Modal.Content>          
          <Modal.Actions>            
            <Button color='grey' onClick={closeForm} icon='delete' content='Закрыть' disabled={loading}/>
            <Button positive  onClick={()=>{ ShowError(null); save({...data}) }} icon='checkmark' content="Записать" disabled={loading || !isChanged}/>
          </Modal.Actions>          
        </Modal>
      )

}


const BackupDatabase=(props)=>{
    
    const [options, setOptions] = React.useState([])
    var { task, client, connector} = props
    var loading=false

    React.useEffect(() => {        
        connector.send('model_database_list', {data:'description,name', order:'description', label: 'backup', filter: {field:'client', value: client}})
        return () => {
            connector.listen['backup_database'] = null            
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
    
    connector.listen['backup_database'] = (data)=>{        
        if (data.action==='model_database_list' && data.label==='backup')  
                setOptions(data.data.map(e=>{ return {key:e.guid, text: e.description || e.name, value: e.guid, icon: 'database' }}))
    }

    const model={        
        name:'backupdatabase',
        cols: 'description,name',
        //extcols: '',
        join: {database: [['description', 'description'], ['name', 'name']]},
        //edit: '',
        order: 'name',
        filter: {field: 'task', value: task},
        fields: {
            description: ['Наименование', 12],
            name: ['Имя', 4],
        },        
        disableAppend: false,
        row_icon: 'database',
        icon: 'database',
        //icons: icons,        
        updateElem: task,
        commands: [],
        form: EditForm,        
        //onSave: onSaveDatabase,
        calc: {description: (v,r)=> v || r.name },
        deep: false,
        //hiddenRows: (hiddenRow.use) ? hiddenRow.rows : [],
        options: options,
    }
    
return <ModelForm {...props} model={model} loading={loading} val_name='database' val_label='База данных'/>
    
}

const volumeTypes=["Локальный", "Samba"]

const BackupVolume=(props)=>{
    
    const [options, setOptions] = React.useState([])
    var { task, client, connector} = props
    var loading=false

    React.useEffect(() => {        
        connector.send('model_volume_list', {data:'name', order:'name', filter: {field:'client', value: client}})
        return () => {
            connector.listen['backup_volume'] = null            
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
    
    connector.listen['backup_volume'] = (data)=>{        
        if (data.action==='model_volume_list')  
                setOptions(data.data.map(e=>{ return {key:e.guid, text:e.name, value: e.guid, icon: 'disk' }}))
    }

    const model={        
        name:'backupvolume',
        cols: 'name, type, size',
        extcols: 'limit',
        join: {volume: [['size', 'size'], ['name', 'name'], ['limit', 'limit'], ['type', 'type']]},
        //edit: '',
        order: 'name',
        filter: {field: 'task', value: task},
        fields: {
            size: ['Занято', 4],
            name: ['Имя', 8],
            limit: ['Лимит', 4],
            type: ['Тип', 4],
        },        
        disableAppend: false,
        row_icon: 'disk',
        icon: 'disk',
        //icons: icons,        
        updateElem: task,
        commands: [],
        form: EditForm,        
        //onSave: onSaveDatabase,
        calc: {size: formatSize, type: (v,r)=> volumeTypes[v]},
        deep: false,
        //hiddenRows: (hiddenRow.use) ? hiddenRow.rows : [],
        options: options,
    }
    
return <ModelForm {...props} model={model} loading={loading}  val_name='volume' val_label='Хранилище'/>
    
}


const EditBackupTask=(props)=>{
	const [data, setData] = React.useState({})    
    const [isChanged, setChanged] = React.useState(false)
    const [error, ShowError] = React.useState(null)
    
    const {open, close, save, loading, isServer, connector}=props
	
    const {model} = props
    const model_icon = model.icon
    
    const filter = {} 
    if (model.filter) filter[model.filter.field] = model.filter.value
    
    React.useEffect(() => {         
        return () => { 
            connector.listen['EditBackupTask'] = null             
        }	
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);    
    React.useEffect(() => { 
        setData({...props.data, depth: props.data.depth || 14 }); 
        setChanged(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])
    
    const handleChange = (e, {name, value }) => {
        setData(p=>{ return{ ...p, [name]: value || null}})
        setChanged(true)
    }
	//const handleChangeCheckbox = (e, {name, checked }) => {  setData(p=>{return{ ...p, [name]: checked }}); setChanged(true)  }
    
    const closeForm=()=>{ShowError(null); close()}
    
    const client_row = model.options.optionsClient.find(e=>e.value===data.client)
    const client_name = ((isServer && data.client) ? " ("+(client_row && client_row.text)+")" : "") + ( (isChanged) ? '*' : '' )
    
    
    const tab1 = (<Form loading={loading} style={{height: "100%"}}>
                  {isServer && <Form.Group>
                    <Form.Dropdown width={16} label='Клиент' name='client' value={data.client || null} onChange={handleChange}
                        selection options={model.options.optionsClient} disabled={props.data.client!==undefined}/>
                  </Form.Group>}
                <Form.Group>
                    <Form.Input width={12} label="Наименование" placeholder='Наименование' name='name' value={data.name || ''}  
                        onChange={handleChange} autoComplete='new-password'	/>
                    <Form.Input width={4} label="Глубина архива (дней)" name='depth' value={data.depth || 14} type='number' 
                        onChange={handleChange} autoComplete='new-password'	/>
                </Form.Group>                
               </Form>)
    const tab2 = <BackupDatabase  style={{height: "100%"}}{...props} task={data.guid} client={data.client}/>
    const tab3 = <BackupVolume  style={{height: "100%"}}{...props} task={data.guid} client={data.client}/>
    const tab4 = <Timing  style={{height: "100%"}}{...props} task={data.task}/>
    const tab5 = <TaskLogs  style={{height: "100%"}}{...props} task={data.task}/>
    
    
    const panes = []
    panes.push({ menuItem: {key: 'setting', content: 'Настройки',  icon:'setting'}, render: () => <Tab.Pane style={{height:"95%"}}>{tab1}</Tab.Pane>})
    if (props.data.guid){
        panes.push({ menuItem: {key: 'database', content: 'Базы данных',  icon:'database'}, render: () => <Tab.Pane style={{height:"95%"}}>{tab2}</Tab.Pane>})
        panes.push({ menuItem: {key: 'volume', content: 'Хранилища',  icon:'disk'}, render: () => <Tab.Pane style={{height:"95%"}}>{tab3}</Tab.Pane>})
        panes.push({ menuItem: {key: 'timing', content: 'Расписание',  icon:'list'}, render: () => <Tab.Pane style={{height:"95%"}}>{tab4}</Tab.Pane>})
        panes.push({ menuItem: {key: 'history', content: 'Журнал',  icon:'history'}, render: () => <Tab.Pane style={{height:"95%"}}>{tab5}</Tab.Pane>})
    }
    
    
    return (
        <Modal
          onClose={closeForm}
          open={open}
          closeOnDimmerClick={false}
          style={{height: '90%'}}
        >
          <Modal.Header><Icon name={model_icon}/>{' '} {data.name}{client_name}</Modal.Header>
          {error && <Message attached='bottom' error content={error}/>}
          <Modal.Content style={{height: '90%'}}>
            <Tab style={{height: '100%'}} panes={panes} />   
            
          </Modal.Content>          
          <Modal.Actions>            
            <Button color='grey' onClick={closeForm} icon='delete' content='Закрыть' disabled={loading}/>
            <Button positive  onClick={()=>{ ShowError(null); save({...data, ...filter}) }} icon='checkmark' content="Записать" disabled={loading || !isChanged}/>
          </Modal.Actions>          
        </Modal>
      )

}

const taskIcon=(row)=>{ return <Icon key={'icon_row'+row.guid} name={'archive'} color={(row.enabled) ? 'green' : 'grey'}/>}

export const BackupTasks=(props)=>{
    const [options, setOptions] = React.useState({optionsClient:[]})
    //const [error, setErrorMessage] = React.useState(null)
    var { client, client_guid, isServer, connector} = props
    var loading=false
    
    client_guid = client || client_guid

    React.useEffect(() => {        
        if (isServer) connector.send('model_client_list', {data:'name', order:'name'})
        return () => {
            connector.listen['backup_tasks_setting'] = null            
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
    
    connector.listen['backup_tasks_setting'] = (data)=>{        
        if (data.action==='model_client_list')  
                setOptions({...options, optionsClient: data.data.map(e=>{ return {key:e.guid, text:e.name, value: e.guid, icon: 'server' }})})
    }

    const model={        
        name:'backuptask',
        cols: 'name'+((!props.hideClient) ? ',client_name' : ''),
        extcols: 'enabled',
        join: {client: [['name', 'client_name']], task: [['enabled', 'enabled']]},
        edit: 'name, depth',
        order: 'name',
        filter: {field: 'client', value: client_guid},
        fields: {
            name: ['Имя', 8],
            client_name: ['Клиент', 8],
            enabled: ['Активно', 8],
            depth: ['Глубина архива', 8],
        },        
        disableAppend: props.disableAppend,
        row_icon: taskIcon,
        icon: 'archive',
        //icons: icons,        
        updateElem: client,
        commands: [],
        form: EditBackupTask,        
        //onSave: onSaveDatabase,
        //calc: {size: formatSize},
        deep: false,
        //hiddenRows: (hiddenRow.use) ? hiddenRow.rows : [],
        options: options,
    }
    
return <ModelForm {...props} model={model} loading={loading}/>
        
}

