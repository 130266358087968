import React from 'react'
import { Tab } from 'semantic-ui-react'
import UsersForm from './users.js'
import OptionsForm from './options.js'
import {ModelForm} from './models.js'
import Volumes from './volume.js'
import Tasks from './task.js'
import { BackupTasks } from './backup.js'
import { UpdateTasks } from './update.js'



const SettingForm=(props)=>{
    
    var {user, isServer} = props
	var {right} = user || 3
    
    
    const model_app={
        name:'application',
        cols: 'name,description,command',
        edit: 'name,description,path,command',
        order: 'name',
        icon: 'terminal',
        fields: {
            name: ['Наименование', 4],
            description: ['Описание', 6],
            command: ['Команда', 6],
            path: ['Каталог программы'],
        }        
    }
    
    const model_account={
        name:'account',
        cols: 'name',
        edit: 'name,login,password',
        row_icon: 'key',
        icon: 'key',
        filter: {field: 'client', value: null},
        order: 'name',
        fields: {
            name: ['Наименование', 4],
            login: ['Логин', 6],
            password: ['Пароль', 6],
        }        
    }
    
    const model_config={
        name:'config',
        cols: 'description',
        edit: 'name,description',
        row_icon: 'database',
        icon: 'database',
        order: 'description',
        fields: {
            name: ['Имя', 4],
            description: ['Наименование', 6],            
        }        
    }
    
    const paneStyle={height: `calc( ${window.innerHeight-10}px  - 7em )`}
    
	const panes = [
		{ menuItem: {content: 'Пользователи', key: 'users', icon: 'users'}, 
			render: () => <Tab.Pane  style={paneStyle}><UsersForm {...props}/></Tab.Pane> },
		{ menuItem: {content: 'Параметры', key: 'setting', icon: 'setting'}, 
			render: () => <Tab.Pane style={paneStyle}><OptionsForm {...props}/></Tab.Pane> },
        { menuItem: {content: 'Приложения', key: 'app', icon: 'terminal'},         
			render: () => <Tab.Pane style={paneStyle}><ModelForm {...props} model={model_app}/></Tab.Pane> },        
        { menuItem: {content: 'Хранилища', key: 'volume', icon: 'disk'},         
			render: () => <Tab.Pane style={paneStyle}><Volumes {...props}/></Tab.Pane> },       
	]	
    
    if (!isServer){
        panes.push({ menuItem: {content: 'Планировщик', key: 'task', icon: 'clock outline'},         
			render: () => <Tab.Pane style={paneStyle}><Tasks {...props} hideClient={!isServer}/></Tab.Pane> })
        panes.push({ menuItem: {content: 'Резервное копирование', key: 'backuptask', icon: 'archive'},         
			render: () => <Tab.Pane style={paneStyle}><BackupTasks {...props} hideClient={!isServer}/></Tab.Pane> })
    }
	
    if (right===1 && isServer) {
        panes.push({ menuItem: {content: 'Обновления баз', key: 'updatetask', icon: 'object group outline'},         
			render: () => <Tab.Pane style={paneStyle}><UpdateTasks {...props}/></Tab.Pane> })
        panes.push({ menuItem: {content: 'Конфигурации 1С', key: 'config', icon: 'sitemap'}, 
			render: () => <Tab.Pane style={paneStyle}><ModelForm {...props} model={model_config}/></Tab.Pane> })
        panes.push({ menuItem: {content: 'Аккаунты', key: 'account', icon: 'key'}, 
			render: () => <Tab.Pane style={paneStyle}><ModelForm {...props} model={model_account}/></Tab.Pane> })
    }
    return (<Tab style={{height:'100%'}} panes={panes} 
                renderActiveOnly 
                menu={{ pointing: true, fluid: true, vertical: true, tabular: false }}/>)
}

export default SettingForm;