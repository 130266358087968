import React from 'react'
import {  Tab, Button, Icon, Modal, Form, Message, Popup, List } from 'semantic-ui-react'
import {DateTimeField} from './datetime.js'
import {ModelForm} from './models.js'
import {formatDateTime, formatDateMySQL} from './func.js'
import {useInterval} from './interval.js'

const Databases=(props)=> {
    const {client} = props
    if (!client) return null
    
    const model={        
        name:'database',
        guidForm: 'update1c',
        cols: 'description,icon,checkbox,config_name,version',
        extcols: 'name',
        join: {config: [['description', 'config_name']]},
        edit: '',
        order: 'description',
        filter: {field: 'client', value: client},
        fields: {
            description: ['Описание', 6],
            config_name: ['Конфигурация', 6],
            version: ['Версия', 2],            
            checkbox: ['', 1],
            icon: ['', 1],
        }, 
        disableAppend: true,
        row_icon: props.rowIcon,
        hideRow: props.hideRow,
        icon: 'database',
        updateElem: client,
        commands: [],
        custom_commands: true,
        form: ()=>null,        
        calc: { icon: props.statusIcon, checkbox: props.checkboxDatabase, description: (a,e)=>(e.description) ? e.description : e.name   },
    } 

    
    
    return <ModelForm key='123' {...props} model={model}/>
}

const queryDB=(task)=>{
    return {
        data:'database, file_name', extcols:'file,file_modified,file_size', 
        filter: {field: 'task', value: task},
        join: {file: [
                    ['name', 'file_name'], 
                    ['modified', 'file_modified'], 
                    ['size', 'file_size'], 
                ]
          },
    }
}

const stagesIcons={
    1: {name: "Скачивание файлов", icon: ["download"], color: ['violet']},
    2: {name: "Резервное копирование", icon: ["disk"], color: ['teal']}, 
    3: {name: "Блокировка", icon: ["lock"], color: ['brown']},
    4: {name: "Обновление", icon: ["code branch"], color: ['blue']}, 
    5: {name: "Принятие изменений", icon: ["code branch", "save"], color: ['blue', 'black']}, 
    6: {name: "Удаление патчей", icon: ["puzzle piece", "remove circle"], color: ['olive', 'red']},
    7: {name: "Процедуры обновления", icon: ["object group outline"], color: ['green']},
    8: {name: "Снятие блокировки", icon: ["lock open"], color: ['brown']},
}

const statusIcon=(row, data)=>{
    if (!data) return null
    
    var icon = null
    var success = true
    var process = false
    const items = data.stages.map((e, ri)=>{
        process = e.begin && !e.end
        const stage = stagesIcons[e.stage]
        success = success && e.success
        
        const descriptions = (e.message && e.message.split('\n')) || []
        var description = <List.Description content={descriptions[0]}/>
        if (descriptions.length>1)  description = (
            <Popup wide on='hover'  pinned position='right center' 
                trigger={<List.Description style={{cursor:'pointer'}}>{descriptions[0]}</List.Description>}>
                    {descriptions.map((dsc, i)=><p style={{margin:0}}key={i}>{dsc}</p>)}
            </Popup>
        )
        
        const icons = stage.icon.map((s,i)=>{
            return <Icon key={i} name={s} color={stage.color[i]}  corner={i===1}  />
        })        
        const row_icon = <Icon.Group  size='large'>{icons}</Icon.Group>
        icon = row_icon        
        
        return (<List.Item key={ri} style={{display:'flex'}}>
            <div>{row_icon}</div>
            <List.Content>
                <List.Header>{stage.name}</List.Header>
                <List.Description style={{cursor:'pointer'}}>{description}</List.Description>
                <List.Description style={{color:'blue', fontSize: '8pt'}}>
                    {formatDateTime(e.begin, true)} - {e.end && formatDateTime(e.end, true)}
                </List.Description>
            </List.Content>
       </List.Item>)
    })
    
    if (success && items.length===8) icon = <Icon name='check' color='green' size='large'/>
    else if (!success && !process) icon = <Icon name='dont' color='red' size='large'/>
    
    return <Popup wide on='click' trigger={icon}><List divided relaxed>{items}</List></Popup>
}

const EditUpdateTask=(props)=>{
	const [data, setData] = React.useState({})    
    const [extData, setExtData] = React.useState({databasesList:[]})
    const [isChanged, setChanged] = React.useState(false)
    const [error, ShowError] = React.useState(null)
    const [options, setOptions] = React.useState({})
    const [formLoading, setLoading] = React.useState(false)
    //const [databasesList, setDatabasesList] = React.useState([])
    
    const {open, close, save, isServer, connector}=props
    var loading = props.loading || formLoading
	
    const {model} = props
    const model_icon = model.icon
    const readOnly = Boolean(extData.begin)
    //const isRunning = Boolean(extData.begin && !extData.end)
    
    const filter = {} 
    if (model.filter) filter[model.filter.field] = model.filter.value
    
    React.useEffect(() => { 
        return () => { connector.listen['EditUpdateTask'] = null } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 
    
    useInterval(() => {
        // Your custom logic here
        if (props.data.guid) connector.send('model_updatetask_get', {guid: data.guid, label: 'update_logs'})
      }, 8000);
    
    React.useEffect(() => {
        setOptions({...model.options})
        setData({...props.data, ...filter });
        if (filter.client) sendListClient(filter.client)
        setExtData({...props.extData, 
                start: props.extData.start || new Date(),  
                databasesList: (props.extData.databases) ? props.extData.databases.map(e=>e.database) : [] 
        })
        setChanged(false) 
        if (props.data.client) sendListClient(props.data.client)
        if (props.data.volume) sendListFile(props.data.volume, 'optionsClientFile')
        //if (props.extData && props.extData.databases) setDatabasesList(props.extData.databases.map(e=> e.database))
        if (isServer){            
            if (props.data.update_file_id) {setLoading(true);connector.send('model_file_get', {guid: props.data.update_file_id, label: 'update_volume'})}
            if (props.data.script_file_id) {setLoading(true);connector.send('model_file_get', {guid: props.data.script_file_id, label: 'script_volume'})}
            if (extData.update_volume) sendListFile(extData.update_volume, 'optionsServerUpdateFile')
            if (extData.script_volume) sendListFile(extData.script_volume, 'optionsServerScriptFile')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])
    
    connector.listen['EditUpdateTask'] = (data)=>{
        if (data.action==='model_volume_list' && data.label==='client')  
            setOptions({...options, optionsVolume: data.data.map(e=>{ return {key:e.guid, text:e.name, value: e.guid, icon:'disk' }})})
        if (data.action==='model_file_list' && data.label)  
            setOptions({...options, [data.label]: data.data.map(e=>{ return {key:e.guid, text:e.name, value: e.guid, icon:'file'}})})
        if (data.action==='model_file_get' && data.label){
            setExtData(p=>{ return{ ...p, [data.label]: data.file.volume}})
            sendListFile(data.file.volume, (data.label==='update_volume') ? 'optionsServerUpdateFile': 'optionsServerScriptFile')
        }
        if (data.action==='model_updatetask_get' && data.label==='update_logs')
            setExtData(p=>{ return{ ...p, databases: [...data.data.databases], begin: data.data.begin}})
        setLoading(false)
    }
    
    const sendListClient=(client)=>{
        connector.send('model_volume_list', {data:'name', order:'name', label: 'client', filter: {field: 'client', value: client}})
        setLoading(true)
    }
    const sendListFile=(volume, name)=>{
        connector.send('model_file_list', {data:'name', order:'name', label: name, filter: {field: 'volume', value: volume}})
        setLoading(true)
    }
    
    const handleChange = (e, {ext, name, value }) => {
        if (ext) setExtData(p=>{ return{ ...p, [name]: value || null}})
            else setData(p=>{ return{ ...p, [name]: value || null}})
        setChanged(true)
        if (name==='client' && value) sendListClient(value)              
        if (name==='volume' && value) sendListFile(value, 'optionsClientFile')
        if (name==='update_volume' && value) sendListFile(value, 'optionsServerUpdateFile')
        if (name==='script_volume' && value) sendListFile(value, 'optionsServerScriptFile')
    }    
	const handleChangeCheckbox = (e, {name, checked }) => {  setData(p=>{return{ ...p, [name]: checked }}); setChanged(true)  }
    
    const closeForm=()=>{ShowError(null); close()}
    
    const client_row = options.optionsClient && options.optionsClient.find(e=>e.value===data.client)
    const client_name = ((isServer && data.client) ? " ("+(client_row && client_row.text)+")" : "") + ( (isChanged) ? '*' : '' )
    
    
    const handleDBCheckbox = (e, {name, checked }) => {
        const guid=name.split('_')[1]
        setExtData((v)=>{
            if (checked) v.databasesList.push(guid)
                else v.databasesList = v.databasesList.filter(r=>r!==guid)
            return v
        })
        setChanged(true)  
    }
    const checkboxDatabase=(val, data)=> <Form.Checkbox key={data.guid}  disabled={readOnly} checked={!!extData.databasesList.find(d=>data.guid===d)} name={'DB_'+data.guid} onChange={handleDBCheckbox}/>   
    const rowIcon=(row)=>{
        const selected = !!extData.databasesList.find(d=>row.guid===d)
        return<Icon key={row.guid} name='database' color={(selected) ? 'blue' : 'grey'}/>
    }
    const hideRow=(row)=> readOnly && !extData.databasesList.find(d=>row.guid===d)
    
    const tab1 = (<div>
                  {(isServer && !props.data.client && !filter.client ) && <Form.Group>
                    <Form.Dropdown width={16} label='Клиент' name='client' value={data.client || null} onChange={handleChange}
                        selection options={options.optionsClient || []} disabled={!!props.data.client}/>
                  </Form.Group>}
                <Form.Group>
                    <Form.Input width={16} label="Наименование" placeholder='Наименование' name='name' value={data.name || ''}  
                        onChange={handleChange} autoComplete='new-password'	 readOnly={readOnly}/>
                </Form.Group>                
                <Form.Group>
                    <Form.Dropdown width={8} label='Хранилище файлов' name='volume' value={data.volume || null} onChange={handleChange}
                        selection options={options.optionsVolume || []}  disabled={readOnly}/>
                    <Form.Dropdown width={8} label='Хранилище резервных копий' name='backup_volume' value={data.backup_volume || null} 
                        onChange={handleChange} selection options={options.optionsVolume || []}  disabled={readOnly}/>
                </Form.Group>
                {isServer && <Form.Group>
                    <Form.Dropdown width={8} label='Хранилище файла обновления' name='update_volume' value={extData.update_volume || null} 
                        onChange={handleChange} selection options={options.optionsServerVolume || []} ext='1'  disabled={readOnly}/>
                    <Form.Dropdown width={8} label='Файл обновления' name='update_file_id' value={data.update_file_id || null} 
                        onChange={handleChange} selection 
                        options={(options.optionsServerUpdateFile || []).filter(e=>e.text.indexOf('.cf')!==-1)}  
                        disabled={readOnly}/>                    
                </Form.Group>}
                {isServer && <Form.Group>
                    <Form.Dropdown width={8} label='Хранилище файла скрипта' name='script_volume' value={extData.script_volume || null} 
                        onChange={handleChange} selection options={options.optionsServerVolume || []} ext='1'  disabled={readOnly}/>
                    <Form.Dropdown width={8} label='Файл скрипта' name='script_file_id' value={data.script_file_id || null} 
                        onChange={handleChange} selection 
                         options={(options.optionsServerScriptFile || []).filter(e=>e.text.indexOf('.epf')!==-1)}  
                         disabled={readOnly}/>                    
                </Form.Group>}
                {!isServer && <Form.Group>
                    <Form.Dropdown width={8} label='Файл обновления' name='update_file' value={data.update_file || null} 
                        onChange={handleChange} selection clearable options={options.optionsClientFile || []} ext='1'  disabled={readOnly}/>
                    <Form.Dropdown width={8} label='Файл скрипта' name='script_file' value={data.script_file || null} 
                        onChange={handleChange} selection clearable options={options.optionsClientFile || []} disabled={readOnly}/>                    
                </Form.Group>}
                <Form.Group>
                    <Form.Checkbox width={10} toggle label='Удалить все патчи перед обновлением'                        
                        name='predelete_patches' checked={data.predelete_patches}  
                        onChange={handleChangeCheckbox}  disabled={readOnly}/> 
                </Form.Group>
                <Form.Group>
                    <DateTimeField form label='Начало' ext='1' value={extData.start} name='start' onChange={handleChange}  disabled={readOnly}/>
                </Form.Group>
            </div>)
    const tab2 = (<Databases style={{height: "100%"}} {...props}  
                client={data.client} checkboxDatabase={checkboxDatabase} hideRow={hideRow} 
                statusIcon={(t,r)=>statusIcon(r, extData.databases && extData.databases.find(d=>d.database===r.guid))}
                rowIcon={rowIcon} checkCount={extData.databasesList && extData.databasesList.length}  readOnly={readOnly}/>)
    
    
    const panes = []
    panes.push({ menuItem: {key: 'setting', content: 'Настройки',  icon:'setting'}, render: () => <Tab.Pane style={{height:"95%"}}>{tab1}</Tab.Pane>})
    panes.push({ menuItem: {key: 'database', content: 'Базы данных',  icon:'database'}, render: () => <Tab.Pane style={{height:"95%"}}>{tab2}</Tab.Pane>})

    const saveData=()=>{
         if(!extData.databasesList.length) {
            ShowError("Укажите базу данных")
            return
        }                             
        ShowError(null);
        setLoading(true)        
        save({...data, ...filter}, { databases: extData.databasesList, start: formatDateMySQL(extData.start)}) 
    }
    
    return (
        <Modal
          onClose={closeForm}
          open={open}
          closeOnDimmerClick={false}
          style={{height: '70%'}}
        >
          <Modal.Header><Icon name={model_icon}/>{' '} {data.name}{client_name}</Modal.Header>
          {error && <Message attached='bottom' error content={error}/>}
          <Modal.Content style={{height: '90%'}}>
            <Form loading={loading} style={{height: "100%"}} readOnly={readOnly}>
                <Tab style={{height: '100%'}} panes={panes} />   
            </Form>
          </Modal.Content>          
          <Modal.Actions>            
            <Button color='grey' onClick={closeForm} icon='delete' content='Закрыть' disabled={loading}/>
            <Button positive  onClick={saveData} icon='checkmark' content="Записать" disabled={loading || !isChanged || readOnly}/>
          </Modal.Actions>          
        </Modal>
      )

}


const taskIcon=(row)=>{ return <Icon key={'icon_row'+row.guid} name={'code branch'} color={(row.enabled) ? 'blue' : 'grey'}/>}

export const UpdateTasks=(props)=>{
    const [options, setOptions] = React.useState({optionsClient:[], optionsServerVolume:[], optionsClientFile:[]})
    var { client, client_guid, isServer, connector} = props
    var loading=false
    
    client_guid = client || client_guid

    React.useEffect(() => {        
        if (isServer) {
            connector.send('model_client_list', {data:'name', order:'name'})
            connector.send('model_volume_list', {data:'name', order:'name', label: 'server', filter: {field: 'client', value: null}})
        }            
        return () => {
            connector.listen['update_tasks_setting'] = null            
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
    
    connector.listen['update_tasks_setting'] = (data)=>{        
        if (data.action==='model_client_list')  
                setOptions({...options, optionsClient: data.data.map(e=>{ return {key:e.guid, text:e.name, value: e.guid, icon:'server' }})})
        if (data.action==='model_volume_list' && data.label==='server')  
                setOptions({...options, optionsServerVolume: data.data.map(e=>{ return {key:e.guid, text:e.name, value: e.guid, icon:'disk' }})})
    }

    const model={        
        name:'updatetask',
        cols: 'name'+((!props.hideClient) ? ',client_name' : ''),
        extcols: 'enabled',
        join: {client: [['name', 'client_name']], task: [['enabled', 'enabled']]},
        edit: 'name',
        order: 'name',
        filter: {field: 'client', value: client_guid},
        fields: {
            name: ['Обновление', 8],
            client_name: ['Клиент', 8],
            enabled: ['Активно', 8],
        },        
        disableAppend: props.disableAppend,
        row_icon: taskIcon,
        icon: 'archive',
        //icons: icons,        
        updateElem: client,
        commands: [],
        form: EditUpdateTask,        
        //onSave: onSaveDatabase,
        //calc: {size: formatSize},
        deep: false,
        //hiddenRows: (hiddenRow.use) ? hiddenRow.rows : [],
        options: options,
    }
    
return <ModelForm {...props} model={model} loading={loading}/>
        
}

