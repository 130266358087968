import React from 'react'
import { Label, Table, Button, Icon, Modal, Form } from 'semantic-ui-react'
import {ContextMenu, CommandPanel} from "./contmenu.js";

const crypto = require("crypto")

const hash = (s)=> { return crypto.createHash("md5").update(s || "").digest("hex")}

const key_right={admin:1, operator:2, user:3}
const value_right=["Пользователь", "Администратор", "Оператор", "Пользователь"]

const EditUsersForm=(props)=>{
	const [data, setData] = React.useState({})
	const {open, close, save, isAdmin, loading, guidUser}=props
	
    
	
	React.useEffect(() => {setData({...props.data})}, [props.data])
	
	const handleChange = (e, {name, value }) => setData(p=>{return{ ...p, [name]: value }})
	const handleChangeDisabled = (e, {name, checked }) => setData(p=>{return{ ...p, [name]: checked }})
	const handleChangeRight = (e, {name, checked }) => {
		setData(p=>{ 
			if (checked) p.right = key_right[name] || 3
			return { ...p }
		})
	}
	
	const right = data.right || 3

	return (
    <Modal
      onClose={close}
      open={open}
	  closeOnDimmerClick={false}
    >
      <Modal.Header><Icon name='user'/>{' '} {data.name}</Modal.Header>
      <Modal.Content>
		<Form loading={loading}>
			<Form.Group>
				<Form.Input width={10} label='ФИО'  
					placeholder='Фамилия И.О.' name='name' value={data.name || ''}  
					onChange={handleChange}/>
				<Form.Group style={{marginTop:'2em'}}>
					<Form.Checkbox toggle label='Заблокирован' name='disabled'
						checked={data.disabled===true}  disabled={!isAdmin}
						onChange={handleChangeDisabled}/>
				</Form.Group>
			</Form.Group>			
			<Form.Group >				
				<Form.Input width={6} label='Логин'   autoComplete='no'
					placeholder='username' name='login' value={data.login || ''} 
					onChange={handleChange}/>				
				<Form.Group style={{marginTop:'2em'}}>
					<Form.Radio label='Администратор' checked={right===1}    disabled={!isAdmin}
						name='admin' onChange={handleChangeRight}/>
					<Form.Radio label='Оператор'      checked={right===2} disabled={!isAdmin}
						name='operator' onChange={handleChangeRight}/>
					<Form.Radio label='Пользователь'  checked={right===3}     disabled={!isAdmin}
						name='user' onChange={handleChangeRight}/>	
				</Form.Group>
			</Form.Group>
			<Form.Group >
				{guidUser===data.guid && <Form.Input width={5} label='Старый пароль'  type='password'  
						autoComplete='new-password'	placeholder='Старый пароль' 
						name='old_password' value={data.old_password || ''} onChange={handleChange}/>}
				<Form.Input width={5} label='Новый пароль'  type='password'  autoComplete='new-password'
					placeholder='Новый пароль' name='password' value={data.password || ''} 
					disabled={!isAdmin && guidUser!==data.guid}
					onChange={handleChange}/>
				<Form.Input width={5} label='Подтверждение'  type='password'   autoComplete='new-password'
					disabled={!isAdmin && guidUser!==data.guid}
					placeholder='Подтверждение пароля' name='__password' value={data.__password || ''}  
					onChange={handleChange}/>
			</Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color='grey' onClick={close} icon='delete' content='Отмена' disabled={loading}/>
        <Button positive  onClick={()=>{ save(data) }} icon='checkmark' content="Записать" disabled={loading}/>
      </Modal.Actions>
    </Modal>
  )
}


const UsersForm=(props)=>{
	
	const [currentRow, setCurrentRow] = React.useState(null)
    const [userList, setUserList] = React.useState([])
	const [dataUserForm, setUserForm] = React.useState({
				data: {}, open: false, 
				close: ()=> setUserForm(p=>{return{...p, open: false, loading:true}}),
				save: ()=>{}				
	})
	const [eventContextMenu, setEventContextMenu] = React.useState(null)
    
    var {user} = props
	var {right} = user || 3
	
	React.useEffect(() => {
		props.connector.send('user_list')
        setUserForm({...dataUserForm, open: false, loading:true})
        return () => {
            props.connector.listen[`user_list`] = null
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	const saveUser=(data)=>{
		if (data.password){
			data.password = hash(data.password)
			if (data.__password)
				data.__password = hash(data.__password)
			if (data.old_password)
				data.old_password = hash(data.old_password)
		}else data.password=null
		props.connector.send('user_set', { data: data })
	}
	
	props.connector.listen[`user_list`] = (data)=>{		
		if (!data.result) return
		
        if (data.action==='delete_row')
			props.connector.send('user_list')
		if (data.action==='user_list')
			setUserList(data.data)
		if (data.action==='user' && data.result)
			setUserForm({...dataUserForm, open: true, loading:false, data: data.user || {}, save: saveUser})
		if (data.action==='user_set'){			
			setUserForm({...dataUserForm, open: false, loading:true})
			setUserList(data.data)
		}
	}
	
	
	
	const handleClick=(guid)=>{
		if (guid) props.connector.send('user', {guid: guid})
			else setUserForm({...dataUserForm, open: true, loading:false, data: {right: 3}, save: saveUser})
	}

    const showContextMenu=(e, guid)=>{        
        e.preventDefault()
        e['guid'] = guid
        setEventContextMenu(e)
    }
    const handleContextMenu = React.useCallback(() => setEventContextMenu(null), []);
    const onClickContextMenu = (e, cmd) =>{
        if (cmd==='add' && right===1) handleClick(null)
        else if (cmd==='edit') handleClick(e.guid)
        else if (cmd==='delete') props.connector.send('delete_row', { data: 'user', guid: e.guid })
    }
    const onClickCommandPanel = (guid, name) =>{
        onClickContextMenu({guid:guid} , name)
    }

	const rows=userList.map((e,i)=>{		
		return ( <Table.Row key={i} guid={e.guid} 
                        style={{cursor:"pointer", fontWeight: (currentRow===e.guid) ? 'bold' : 'normal'}} positive={e.guid===currentRow}
                        onDoubleClick={()=>handleClick(e.guid)} 
                        onContextMenu={ (ev)=>{ev['textRow']=e.name; showContextMenu(ev, e.guid)} }
                        onClick={()=>setCurrentRow(e.guid)}>
			<Table.Cell width={1} textAlign='center'><Label circular color={e.active ? 'green' : 'grey'} size='mini'/></Table.Cell>
			<Table.Cell width={4}>
                <div style={{display:'flex'}}>
                    <div><Icon name={e.disabled ? 'user close' : 'user'} color={e.disabled ? 'red' : 'blue'} /></div>
                    <div className='td-text'>{e.name}</div>
                </div>
            </Table.Cell>
			<Table.Cell width={3}>{e.login}</Table.Cell>
			<Table.Cell width={8}>Права: {value_right[e.right || 3]}</Table.Cell>
		</Table.Row>)
	})
	
	
    
    return (<div style={{height: '100%'}}>
		<EditUsersForm {...dataUserForm } isAdmin={right===1} guidUser={user.guid}/>
        <CommandPanel onClick={onClickCommandPanel} guid={currentRow}/>
        <div className='model list' style={{paddingTop:'0', height: (right!==1)?'100%':'95%', overflowY:'auto'}}>  
		 <Table celled striped selectable fixed>
    <Table.Header>
      <Table.Row onClick={()=>setCurrentRow(null)}>
        <Table.HeaderCell width={1}></Table.HeaderCell>
        <Table.HeaderCell width={4}>Имя</Table.HeaderCell>
        <Table.HeaderCell width={3}>Логин</Table.HeaderCell>
		<Table.HeaderCell width={8}></Table.HeaderCell>		
      </Table.Row>
    </Table.Header>
	<Table.Body>
			{rows}  
		</Table.Body>
  </Table>
  </div>
    <ContextMenu event={eventContextMenu} open={eventContextMenu!==null} onClose={handleContextMenu} onClick={onClickContextMenu}/>  
	</div>)
}

export default UsersForm;