import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import Clients from './clients.js'
import PortsForm from './ports.js'
import DatabasesForm from './databases.js'
import { BackupTasks } from './backup.js'
import { UpdateTasks } from './update.js'

const Tasks1=(props)=>{
	return (<Segment raised style={{height: "100%"}}>
            <BackupTasks {...props} disableAppend={true} hideClient={true}/>
	</Segment>)
}

const Tasks2=(props)=>{
	return (<Segment raised style={{height: "100%"}}>
            <UpdateTasks {...props} disableAppend={true} hideClient={true}/>
	</Segment>)
}

const Databases=(props)=>{
	return (<Segment raised style={{height: "100%"}}>
         <DatabasesForm {...props}/>
	</Segment>)
}

const DashboardForm=(props)=>{   
    const [dataClient, setDataClient] = React.useState({})
	const [loading, setLoading] = React.useState(false)
	
	const {isServer, connector, runApp, changeProgress} = props
	const {user} = props
	const {right} = user || 3
 
    const childProps = {connector: connector, 
                        isServer: isServer, 
                        right: right, 
                        user: user,
                        isAdmin: right===1, 
                        loading: loading                      
                        }
	
    React.useEffect(() => {        
        //setLoading(true)
        return () => { props.connector.listen['dashboard'] = null };
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
    
    
	props.connector.listen['dashboard'] = (data)=>{
        if (data.action==='app')  runApp(data.data)
		//setLoading(false)        
	}
	
       
	const clientClick=(guid, row)=>{  setDataClient(row||{}) }
	
	const ports = <PortsForm {...childProps} client={dataClient.guid} clientName={dataClient.name} connected={dataClient.connected}/>
                        
    const clients = <Clients {...childProps}
                        changeProgress={changeProgress}                        
                        onClick={clientClick} />
    
	var heightRow=(isServer) ? '50%' : '47%'	
	return (
		<Grid columns={2} stackable style={{height: "100%"}}>
        { !isServer && clients}
		<Grid.Row stretched style={{height: heightRow, paddingBottom: 0 }}>
			<Grid.Column  style={{height: "100%"}}>
				{ isServer && clients}
				{ !isServer && <Tasks1 {...props}/>}
			</Grid.Column>
			<Grid.Column style={{paddingLeft: '0'}}>
				{ isServer && ports}
                { !isServer && <Tasks2 {...props}/>}				
			</Grid.Column>
		</Grid.Row>
		<Grid.Row  stretched style={{height: heightRow, width: '100% !important', flexGrow: 1}}>
			<Grid.Column  style={{height: "100%", width: '100%'}}>
				<Databases client={dataClient.guid}  clientName={dataClient.name} connected={dataClient.connected}
                    user={user} 
                    connector={props.connector} 
                    isServer={isServer}
                    changeProgress={changeProgress}/>
			</Grid.Column>			
		</Grid.Row>
		</Grid>)
}

export default DashboardForm;