import React from 'react'
import { Segment, Button, Icon, Modal, Form, Tab, Grid, Header } from 'semantic-ui-react'
import {ModelForm} from './models.js'
import Changes from './changes.js'
import Volumes from './volume.js'
import Tasks from './task.js'
import {BackupTasks} from './backup.js'
import {UpdateTasks} from './update.js'

const crypto = require("crypto")
const hash = (s)=> { return crypto.createHash("md5").update(s || "").digest("hex")}


const EditClient=(props)=>{
	const [data, setData] = React.useState({})
    const [options, setOptions] = React.useState({optionsAccount:[]})    
	const [authData, setAuthData] = React.useState({login:'', password:''})
	const {close, save, isAdmin, isServer, open, connector, changeProgress}=props
    //const [isChanged, setChanged] = React.useState(false)
    //const [error, ShowError] = React.useState(null) 
    const [loading, setLoading] = React.useState(true) 

    const {model} = props
    const {authStatus} = model || {}
    
    const filter = {} 
    if (model.filter) filter[model.filter.field] = model.filter.value

	
	React.useEffect(() => {
        setData({...props.data})
        setLoading(true)
        connector.send('model_account_list', {data:'name', order:'name', filter: {field:'client', value: props.data.guid}, label: 'form_'+props.data.guid})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])
    
    React.useEffect(() => {        
        return () => { connector.listen['edit_client_options'] = null };
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
    
    connector.listen['edit_client_options'] = (data)=>{        
        if (data.action==='model_account_list' && data.label==='form_'+props.data.guid) //Перехватим список аккаунтов со страницы Аккаунты            
            setOptions({...options, optionsAccount: data.data.map(e=>{ return {key:e.guid, text:e.name, value: e.guid, icon:'key' }})})           
        else if (data.action==='send_client_auth') close()
        else return
        setLoading(false)
    }
	
	const handleChange = (e, {name, value }) => setData(p=>{return{ ...p, [name]: value }})
	const handleChangeCheckbox = (e, {name, checked }) => setData(p=>{return{ ...p, [name]: checked }})
	const handleChangeAuthData = (e, {name, value }) => setAuthData(p=>{return{ ...p, [name]: value }})
	
	
	const copyKey=(e,t)=>{  navigator.clipboard.writeText(data.ssh_key)	}	
	const newKey=()=>{ generateSSHKey()	}
	
	
	//const right = data.hasOwnProperty('right') ?  data.right : {}
    
    const sendAuth=(data)=>{
		data.password = hash(data.password)
		connector.send('send_client_auth', data)
        setLoading(true)
	}
	
	const generateSSHKey=()=>{
		connector.send('generate_rsa_key', {guid: data.guid})
	}
    
    
    
	
	var auth_fields = null
	if (!authStatus) auth_fields = (<Form.Group>
					<Form.Input width={15} label='Логин'  
						placeholder='admin' name='login' value={authData.login} 
						autoComplete='new-password'
						onChange={handleChangeAuthData} readOnly={!isAdmin}/>
					<Form.Input width={15} label='Пароль' type='password' 
						placeholder='qwerty' name='password' value={authData.password} 
						autoComplete='new-password'
						onChange={handleChangeAuthData} readOnly={!isAdmin}/>
					<Button positive onClick={()=>sendAuth(authData)} icon='key' content='Авторизация' disabled={loading || !isAdmin}/>
				</Form.Group>)
	
	const panes = [ 
		{ menuItem: 'Общее',
			pane: (<Tab.Pane key='main' style={{height: '95%'}}>
				<Form.Group>
					<Form.Input width={13} label='Наименование'
						placeholder='ООО Рога и копыта' name='name' value={data.name || ''}  
						onChange={handleChange} readOnly={!isAdmin || !authStatus}/>
                    <Form.Checkbox  width={3} toggle label='Это клиент'
						style={{marginTop: '2em'}}
						name='is_client' checked={data.is_client}  
						onChange={handleChangeCheckbox} readOnly={!isAdmin  || !authStatus}/>
				</Form.Group>
				{auth_fields}
				<Form.Group>
					<Form.Checkbox  width={6} toggle label='Использовать HTTP-соединение'
						style={{marginTop: '2em'}}
						name='http_exchange' checked={data.http_exchange}  
						onChange={handleChangeCheckbox} readOnly={!isAdmin  || !authStatus} disabled={data.is_client}/>
					<Form.Input width={3} label='Интервал'  type='number'
						name='http_interval' value={data.http_interval || ''}  
						onChange={handleChange} readOnly={!isAdmin || !authStatus}  disabled={data.is_client || !data.http_exchange}/>						
					<Form.Input width={4} label='Активность' readOnly={true}
						value={data.last_date || ''}  />
                        
                    <Form.Checkbox  width={3} toggle label='TCP-туннель'
						style={{marginTop: '2em'}}
						name='tunnel' checked={data.tunnel}  
						onChange={handleChangeCheckbox} readOnly={!isAdmin  || !authStatus} disabled={data.is_client}/>
				</Form.Group>
                <Form.Group>
                <Form.Dropdown clearable selection width={8} label='Администратор кластера 1С'  
                    placeholder='Не задан' name='_claster_account' value={data._claster_account || null}
                    options={options.optionsAccount}  readOnly={!isAdmin  || !authStatus}
                    onChange={handleChange}/>
                </Form.Group>
			</Tab.Pane>),
	    }
    ]
	if (!data.is_client) panes.push({ menuItem: 'SSH',
			pane: (<Tab.Pane key='ssh' style={{height: '95%'}}>
				<Form.Group>
					<Form.Input width={16} label='SSH-сервер'
						placeholder='user@myhost.ru:22' name='ssh_host' value={data.ssh_host || ''}  
						onChange={handleChange} readOnly={!isAdmin || !authStatus}/>
				</Form.Group>
				<Form.Group>
					 <Form.TextArea width={15} label='Public RSA key' placeholder='Public RSA key' 
						name='ssh_key' value={data.ssh_key || ''}
						style={{height: '9.4em'}}
						onChange={handleChange} readOnly={true}/>
					<Form.Group width={1}>
						<div style={{marginTop: '1.8em', marginLeft: '.3em'}}>
							<Form.Button onClick={newKey} icon='key' 
									disabled={isServer || loading || !isAdmin || !authStatus}/>
							<Form.Button style={{marginTop: '0.2em'}} onClick={copyKey} icon='copy'									 
									disabled={loading || !isAdmin || !authStatus}/>
						</div>
					</Form.Group>
				</Form.Group>
			</Tab.Pane>),
	    })
	
    
    
    
    const model_account={
        name:'account',
        cols: 'name',
        edit: 'name,login,password',
        row_icon: 'key',
        filter: {field: 'client', value: props.data.guid},
        order: 'name',
        fields: {
            name: ['Наименование', 4],
            login: ['Логин', 6],
            password: ['Пароль', 6],
        }        
    }
    const modelProps={model: model_account, user: props.user, connector: props.connector }
	panes.push({ menuItem: 'Аккаунты', pane: <Tab.Pane key='accounts' style={{height: '95%'}}> <ModelForm {...modelProps} /></Tab.Pane> })
    const subProps={isServer: isServer, client: props.data.guid, user: props.user, connector: props.connector, hideClient:true}        
    if (isServer){        
        panes.push({ menuItem: 'Хранилища', pane: <Tab.Pane key='volumes' style={{height: '95%'}}> 
                <Volumes {...subProps} changeProgress={changeProgress}/>
            </Tab.Pane> })
        panes.push({ menuItem: 'Планировщик', pane: <Tab.Pane key='tasks' style={{height: '95%'}}><Tasks {...subProps}/></Tab.Pane> })
        panes.push({ menuItem: 'Backups', pane: <Tab.Pane key='backups' style={{height: '95%'}}><BackupTasks {...subProps}/></Tab.Pane> })
        panes.push({ menuItem: 'Обновления 1С', pane: <Tab.Pane key='updates' style={{height: '95%'}}><UpdateTasks {...subProps}/></Tab.Pane> })
    }
    panes.push({ menuItem: 'Обмен', pane: (<Tab.Pane key='changes' style={{height: '95%'}}> <Changes {...subProps}/> </Tab.Pane>  ) })
    
	return (
    <Modal size='large' onClose={close}  open={open}  closeOnDimmerClick={false} style={{height: '85%'}}>
		<Modal.Header><Icon name='server'/>{' '} {data.name}</Modal.Header>
		<Modal.Content style={{height: '90%'}}>
			<Form loading={loading} style={{height: '100%'}}>				
				<Tab panes={panes} renderActiveOnly={false} style={{height: '100%'}}/>
			</Form>
		</Modal.Content>
		<Modal.Actions>
			<Button color='grey' onClick={close} icon='delete' content='Отмена' disabled={loading}/>
			<Button positive  onClick={()=>{ save(data) }} icon='checkmark' content="Записать" 
					disabled={loading || !isAdmin || !authStatus}/>
		</Modal.Actions>
    </Modal>
  )
}

const Clients=(props)=>{
    const [listTunnels, setListTunnels] = React.useState({clients:[]})    
    const [localClient, setLocalClient] = React.useState({})
    const [authStatus, setAuthStatus] = React.useState(false)
    var { isServer, connector} = props
    var loading=false
    
        
    React.useEffect(() => { 
        setAuthStatus(isServer)    
        return () => { connector.listen['client_form'] = null };
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);    
    
    connector.listen['client_form'] = (data)=>{   
        if(!isServer && data.server) setAuthStatus(data.server.auth)
        if (data.action==="tunnels") setListTunnels(data.data) 
        if (data.tunnels) setListTunnels(data.tunnels)
        //else if (data.action==='client_status') setClientStatus(data.client)
        else if (!isServer && data.action==='client_list' &&  data.data.length) setLocalClient(data.data[0])     
    }


    const iconStatus=(e, data)=>{
        var tun_data = listTunnels.clients.find(t=> data.guid===t)
        //console.log(listTunnels, data.name)
        const tunnel = tun_data!==undefined
        const count = (listTunnels.detail && listTunnels.detail.filter(d=> d.client===data.guid).length) || 0
        
        return (<div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            <Icon.Group size='large' >
                <Icon name={data.is_client ? 'desktop' : 'server' }  color={(data.status || (tunnel && data.is_client)) ? 'green' : 'grey'}/>
                {!data.is_client && <Icon corner name={ data.http_exchange ? 'long arrow alternate left' : 'exchange'} color='blue'/>}
            </Icon.Group>
            <Icon.Group size='large' >            
                <Icon name='exchange'  color={tunnel ? 'green' : 'grey'} />
                {!tunnel && data.tunnel && <Icon corner='bottom right' name='close' color='red'/>}                    
                {!tunnel && !data.tunnel && <Icon corner='bottom right' name='pause' color='red'/>}
                {tunnel && !!count  && <Icon corner='bottom right' name='circle' color='blue'/>} 
             </Icon.Group>
        </div>)
    }

    const model={        
        name:'client',
        cols: 'status, name',
        customList: 'client_list',
        //extcols: 'account, port, db_name, scheduled_jobs_deny, sessions_deny, url',
        //join: {client: [['name', 'client_name']]},
        //edit: 'name,path',
        order: 'name',
        //filter: {field: 'client', value: client_guid},
        fields: {
            status: ['', 2,,,{paddingTop:0, paddingBottom:0}],
            name: ['Клиент', 10],
        },        
        disableAppend: true,
        row_icon: ()=>null,
        icon: 'disk',
        //icons: icons,        
        //updateElem: client,
        commands: [],
        hideCommandPanel: true,
        form: EditClient,        
        //onSave: onSaveDatabase,
        calc: {status: iconStatus},
        deep: false,
        onRowSelect: (guid, data)=>props.onClick(guid, {...data, connected: listTunnels.clients.find(t=> guid===t)!==undefined}),
        //changeProgress: changeProgress,
        //hiddenRows: (hiddenRow.use) ? hiddenRow.rows : [],
        //options: options,
        authStatus: authStatus,
        guidForm: 'main_client_form'
    }
    if (isServer) return <Segment raised style={{height: "100%"}}><ModelForm {...props} model={model} loading={loading}/></Segment>

    const editLocalClient=()=>{
        if (localClient.guid) connector.send(`model_${model.name}_get`, {guid: localClient.guid, deep: model.deep, label: model.guidForm})
    }
    return(
    <Grid.Row columns={1} style={{paddingBottom: '0'}}>
        <ModelForm {...props} model={model} loading={loading} hideList={true}/>
        <Grid.Column> 
            <Segment>
            <Grid><Grid.Row columns={2} style={{padding: '0'}}>
                <Grid.Column width={14} style={{padding: '0.7rem'}}>
                    <Header color={authStatus ? 'black' : 'red'}>
                        <Icon name='server' color={authStatus ? 'blue' : 'red'}/>
                        <Icon name='exchange' color={localClient.tunnel ? 'green' : 'red'} size='large'/>
                        <Header.Content >{localClient.name || ''}</Header.Content>			
                    </Header>
                </Grid.Column>
                <Grid.Column width={2} textAlign='right' verticalAlign='middle'>
                    <Button circular icon='settings' onClick={editLocalClient} />
                </Grid.Column>
            </Grid.Row></Grid>
            </Segment>
        </Grid.Column>
    </Grid.Row>)
    
        
}


export default Clients