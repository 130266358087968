import React from 'react'
import {  Icon } from 'semantic-ui-react'
import {ModelForm} from './models.js'

const row_icon=(row)=>{
    return <Icon key={"row-ico"+row.guid} name={row.deleted ? 'cancel' : 'sync'} color={row.deleted ? 'red' : 'blue'}/>
}

const Changes=(props)=>{
    //const [error, setErrorMessage] = React.useState(null)
    var { client, connector} = props
    var loading=false

    React.useEffect(() => {        
        return () => {
            connector.listen['changes_form'] = null            
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
    
    connector.listen['changes_form'] = (data)=>{        
        
    }

     
    
    const model={        
        name:'changes',
        cols: 'record,model,send,recv',
        extcols: 'deleted',
        //join: {client: [['name', 'client_name']]},
        //edit: '',
        //order: 'name',
        filter: {field: 'client', value: client},
        customList: 'change_list',
        fields: {
            model: ['Модель', 4],
            record: ['Представление', 8],
            send: ['Отправлено', 2],
            recv: ['Принято', 2],
        },        
        disableAppend: true,
        row_icon: row_icon,
        icon: 'sync',
        //icons: icons,        
        updateElem: client,
        commands: [{name:'delete'}, {name:'reload', all:true}],
        custom_commands: true,
        form: ()=> { return null},        
        //onSave: onSaveDatabase,
        //calc: {size: formatSize, limit: (v)=> {return (v) ? formatSize(v) : 'Нет'} },
        deep: false,
        //changeProgress: changeProgress,
        //hiddenRows: (hiddenRow.use) ? hiddenRow.rows : [],
        //options: options,
    }
    //if (!isServer) model['filter']={field: 'client', value: client_guid}
    
    
    
    
    
    
return <ModelForm {...props} model={model} loading={loading}/>
        
}

export default Changes;

