import React from 'react'
import { Icon, Button, Modal, Form, Tab, Checkbox } from 'semantic-ui-react'
import {ModelForm} from './models.js'
import {formatDateTime, formatSize} from './func.js'
import {downloadFile} from './files.js'
import {DateTimeField} from './datetime.js'


const MainPane=({data, readOnly, isServer, options, handleChange, handleChangeCheckbox})=>{
    if (!data) return null;
    const {optionsPort, optionsApp, optionsAccount, optionsConfig} = options
    
    return (<div>    
    <Form.Group>
        <Form.Input width={13} label='Наименование'
            placeholder='База бухгалтерии' name='description' value={data.description || ''}  
            onChange={handleChange} readOnly={readOnly}/>
        <Form.Input width={3} label='Имя' name='description' value={data.name || ''} readOnly/>        
    </Form.Group>
    
    {isServer && 
    <Form.Group>
        <Form.Dropdown clearable selection width={8} label='Приложение веб-клиента'  
            placeholder='1C:Предприятие' name='app' value={data.app || null}
            options={optionsApp}  readOnly={readOnly}
            onChange={handleChange}/>    
        <Form.Dropdown clearable selection width={8} label='Порт для веб-клиента'  
            placeholder='web' name='port' value={data.port || null}
            options={optionsPort}  readOnly={readOnly}
            onChange={handleChange}/>        
    </Form.Group> }
    
    <Form.Group>
        <Form.Dropdown clearable selection width={12} label='Конфигурация'  
            name='config' value={data.config || null}
            options={optionsConfig}  readOnly={readOnly}
            onChange={handleChange}/>
        <Form.Input width={2} label='Версия' name='version' value={data.version || ''} readOnly/>        
        <Form.Input width={2} label='Размер' name='size' value={formatSize(data.size) || ''} readOnly/> 
    </Form.Group>

    <Form.Group>
        <Form.Input width={8} label='http-адрес'    readOnly={readOnly}
            placeholder='http://localhost/base-name' name='url' value={data.url || ''}
            onChange={handleChange}/>
        <Form.Dropdown clearable selection width={4} label='Пользователь'  
            placeholder='Админ' name='account' value={data.account || null}
            options={optionsAccount}  readOnly={readOnly}
            onChange={handleChange}/>
        <Form.Input width={4} label='Обновлено' name='last_update' value={data.last_update || ''} readOnly/> 
    </Form.Group>
  
</div>)}

const ConnectPane=({data, readOnly, isServer, options, handleChange, handleChangeCheckbox})=>{
        
    if (!data) return null;
    const {optionsAccount} = options
    return (<div> 
    <Form.Group>
        <Form.Input width={3} label='СУБД' name='dbms' value={data.dbms || ''} readOnly/>  
        <Form.Input width={5} label='Сервер' name='db_server' value={data.db_server || ''} readOnly/>        
        <Form.Input width={4} label='Имя базы данных' name='db_name' value={data.db_name || ''} readOnly/> 
        <Form.Dropdown clearable selection width={4} label='Пользователь'  
            placeholder='sa' name='db_account' value={data.db_account || null}
            options={optionsAccount}  readOnly={readOnly}
            onChange={handleChange}/>
    </Form.Group>
    
    <Form.Group>
        <Form.Checkbox width={10} toggle label='Блокировка регламентных заданий'
            style={{marginTop: '2em'}}
            name='scheduled_jobs_deny' checked={data.scheduled_jobs_deny}  
            onChange={handleChangeCheckbox} readOnly={readOnly}/> 
    </Form.Group>
    <Form.Group>
        <Form.Checkbox width={4} toggle label='Блокировка сеансов' style={{    lineHeight: '2.2em'}}            
            name='sessions_deny' checked={data.sessions_deny}  
            onChange={handleChangeCheckbox} readOnly={readOnly}/>
  
        <DateTimeField width={4} clear label='с'  value={data.denied_from} name='denied_from' onChange={handleChange} readOnly={readOnly} />
        <div style={{ lineHeight: '2.2em', width: '1em', textAlign: 'center', fontWeight: 'bold'}}> &ndash; </div>
        <DateTimeField width={4} clear label='по' value={data.denied_to} name='denied_to' onChange={handleChange} readOnly={readOnly} />

    </Form.Group>
    
    <Form.Group>
        <Form.TextArea width={8} label='Сообщение блокировки'
            name='denied_message' value={data.denied_message || ''}  
            onChange={handleChange} readOnly={readOnly}/>    
        <Form.Input width={4} label='Код'
            name='permission_code' value={data.permission_code || ''}  
            onChange={handleChange} readOnly={readOnly}/> 
        <Form.Input width={4} label='Параметр блокировки'
            name='denied_parameter' value={data.denied_parameter || ''}  
            onChange={handleChange} readOnly={readOnly}/>    
        
    </Form.Group>
    
    <Form.Group>        
        <Form.Checkbox  width={8}  toggle label='Выдача лицензий сервером'
            style={{marginTop: '2em'}}
            name='license_distribution' checked={data.license_distribution}  
            onChange={handleChangeCheckbox} readOnly={readOnly}/>         
    </Form.Group>
    
    
</div>)}


const backupIcon=(e)=>{
    if (e.file_locked) return (<Icon.Group key={'icon_'+e.guid}>
            <Icon name='archive' color='blue'/>
            <Icon name='lock' color='red' corner/>
          </Icon.Group>)
    else 
        return <Icon key={'icon_'+e.guid} name='archive' color='blue'/>
}


const BackupPane=(props)=>{        
    const {database, isServer, fileIcons} = props
    if (!database) return null;
    var loading=false
    
    
    const model={        
            name:'backup',
            cols: 'file_name, file_modified, file_size',
            extcols: 'file_locked,file',
            join: {file: [
                            ['guid', 'file'], 
                            ['name', 'file_name'], 
                            ['modified', 'file_modified'], 
                            ['size', 'file_size'], 
                            ['locked', 'file_locked']
                        ]
                  },
            edit: '',
            order: 'file_modified',
            filter: {field: 'database', value: database},
            fields: {
                file_name: ['Имя', 10],
                file_modified: ['Дата', 4],
                file_size: ['Размер', 2],
            }, 
            form:()=> {return null},
            disableAppend: true,
            row_icon: backupIcon,
            icon: 'disk',
            icons: fileIcons,        
            updateElem: 0,
            commands: [],
            custom_commands: true,
            //form: ()=> {return null},        
            //onSave: onSaveDatabase,
            calc: {file_size: formatSize, file_modified: formatDateTime },
            //hiddenRows: (hiddenRow.use) ? hiddenRow.rows : []
        }
    
    const downloadClick=(data, guid)=>{
        downloadFile({guid:data.rowData.file, session: props.connector.guid, changeProgress: props.changeProgress})
    }
    model['commands'].push({name: 'delete'})
    if (!isServer)  model['commands'].push({name: 'download', text: 'Скачать', icon:'download', color: 'blue', all: false, handle: downloadClick})
    
    return <ModelForm {...props} model={model} loading={loading}/>
}


const EditDatabase=(props)=>{
	const [data, setData] = React.useState({})  
    const [options, setOptions] = React.useState({optionsPort:[], optionsApp:[], optionsAccount:[], optionsConfig:[]})
	const {open, close, save, loading, isAdmin, isServer, connector}=props
    
    const {model} = props
    const model_icon = model.icon || 'sticky note outline'
    
    const filter = {} 
    if (model.filter) filter[model.filter.field] = model.filter.value
	
    React.useEffect(() => {        
        connector.send('model_application_list', {data:'name', order:'name'})        
        connector.send('model_config_list', {data:'description', order:'name'})
        return () => {
            connector.listen['database_options'] = null            
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
    React.useEffect(() => {        
        connector.send('model_port_list', {data:'name', order:'name', filter: model.filter})
        connector.send('model_account_list', {data:'name', order:'name', label: 'database', filter: model.filter})
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ model.filter.value]);
    
    connector.listen['database_options'] = (data)=>{        
        if (data.action==='model_application_list')  
                setOptions({...options, optionsApp: data.data.map(e=>{ return {key:e.guid, text:e.name, value: e.guid }})})
        if (data.action==='model_port_list')  
                setOptions({...options, optionsPort: data.data.map(e=>{ return {key:e.guid, text:e.name, value: e.guid }})})
        if (data.action==='model_account_list' && data.label==='database')  
                setOptions({...options, optionsAccount: data.data.map(e=>{ return {key:e.guid, text:e.name, value: e.guid }})})
        if (data.action==='model_config_list')  
                setOptions({...options, optionsConfig: data.data.map(e=>{ return {key:e.guid, text:e.description, value: e.guid }})})
    }
    
    
    
    React.useEffect(() => {setData({...props.data})}, [props.data])
	
	const handleChange = (e, {name, value }) => setData(p=>{return{ ...p, [name]: value || null}})
	const handleChangeCheckbox = (e, {name, checked }) => setData(p=>{return{ ...p, [name]: checked }})

    const propsPanes = {data: data, readOnly: !isAdmin, isServer: isServer, options: options,
                handleChange: handleChange, handleChangeCheckbox: handleChangeCheckbox }
    
    const panes=[]
    panes.push({ menuItem:  {content: 'Общее', key: 'database', icon: 'list alternate outline'}, 
                        pane: <Tab.Pane key='main' style={{height: '95%'}}>{MainPane(propsPanes)}</Tab.Pane> })
    panes.push({ menuItem:  {content: 'Управление', key: 'connect', icon: 'database'}, 
                        pane: <Tab.Pane key='connect' style={{height: '95%'}}>{ConnectPane(propsPanes)}</Tab.Pane> })
    panes.push({ menuItem:  {content: 'Резервные копии', key: 'backup', icon: 'disk'}, 
                        pane: <Tab.Pane key='backup' style={{height: '95%'}}>{BackupPane({...props, database: data.guid})}</Tab.Pane> })

	return (
        <Modal
          onClose={close}
          open={open}
          closeOnDimmerClick={false}
          style={{height: '90%'}}
        >
          <Modal.Header><Icon name={model_icon}/>{' '} {data.name}</Modal.Header>
          <Modal.Content style={{height: '90%'}}>
            <Form loading={loading} style={{height: '100%'}}>
                <Tab panes={panes} renderActiveOnly={false} style={{height: '100%'}}/>			
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color='grey' onClick={close} icon='delete' content='Отмена' disabled={loading}/>
            <Button positive  onClick={()=>{ save({...data, ...filter}) }} icon='checkmark' content="Записать" disabled={loading}/>
          </Modal.Actions>
        </Modal>
      )
}


const rowIcon=(e, isServer)=>{
    var color = ((e.db_name && e.account && e.port && e.url) || !isServer) ? 'blue' : 'grey'
        color = (e.scheduled_jobs_deny) ? 'yellow' : color
        color = (e.sessions_deny) ? 'brown' : color
        color = (!e.db_name ) ? 'red' : color
        
    return (<Icon.Group key={'icon'+e.guid}>
        <Icon name='database' color={color}/>
        { (e.scheduled_jobs_deny || e.sessions_deny) && <Icon corner name='pause' color='red'/> }
      </Icon.Group>)
}
const rowHide=(e)=>{
    return (!e.url || !e.db_name || e.hide)
}


const DatabasesForm=(props)=>{
    const [loading, setLoading] = React.useState(false)
    const [hiddenRow, setHiddenRow] = React.useState(true)
    var { client, isServer, connected} = props
    
    const onSaveDatabase=()=>{  if (!isServer) setLoading(true) }  
    
   
    const model={        
        name:'database',
        cols: 'name,description,config_name,version,size',
        extcols: 'account, port, db_name, scheduled_jobs_deny, sessions_deny, url',
        join: {config: [['description', 'config_name']]},
        edit: 'name,description',
        order: 'name',
        filter: {field: 'client', value: client || null},
        fields: {
            name: ['Имя', 2],
            description: ['Описание', 4],
            config_name: ['Конфигурация', 6],
            version: ['Версия', 2],
            size: ['Размер', 2],
        }, 
        disableAppend: true,
        row_icon: (e)=> rowIcon(e, isServer),
        hideRow: (e) => (hiddenRow) ? rowHide(e) : false,
        icon: 'database',
        updateElem: client,
        commands: [],
        form: EditDatabase,        
        onSave: onSaveDatabase,
        calc: {size: formatSize},
        //hiddenRows: (hiddenRow.use) ? hiddenRow.rows : []
    }
    
    
    
    const updateDatabases=(data, guid)=>{
        const list_options = { data: model.cols, filter: model.filter, order: model.order }
        props.connector.send('update_databases', list_options)
        setLoading(true)    
    }
    
    const runDatabase=(data, guid)=>{
        props.connector.send('app', { model: 'database', guid: guid})
    }
    
    
    React.useEffect(() => {  return () => { props.connector.listen['database_list'] = null }
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);
    
    props.connector.listen['database_list'] = (data)=>{ 
        if (data.action==='done_read_databases') setLoading(false)
        /*else if (data.action==='model_database_list'){            
            var icons = [], hideRow=[]
            data.data.forEach(e=>{
                var color = ((e.db_name && e.account && e.port && e.url) || !isServer) ? 'blue' : 'grey'
                    color = (e.scheduled_jobs_deny) ? 'yellow' : color
                    color = (e.sessions_deny) ? 'brown' : color
                    color = (!e.db_name ) ? 'red' : color
                    
                var icon = (<Icon.Group key={'icon'+e.guid}>
                    <Icon name='database' color={color}/>
                    { (e.scheduled_jobs_deny || e.sessions_deny) && <Icon corner name='pause' color='red'/> }
                  </Icon.Group>)
                icons.push({guid: e.guid, icon: icon})
                if (!e.url || !e.db_name || e.hide) hideRow.push(e.guid)
            })
            setIcons(icons)
            setHiddenRow({use: hiddenRow.use, rows: hideRow})
        }*/
    }
    
    
    if (!isServer){
        model['commands'].push({name: 'update', text: 'Актуализировать', icon:'sync', color: 'green', all: true, handle: updateDatabases})
        //model['commands'].push({name: 'lock', text: 'Блокировка', icon:'lock', color: 'brown', all: false, handle: lockDatabase})        
    }else {
        if (connected) 
            model['commands'].push({name: 'start', text: 'Запустить', icon:'play', color: 'blue', all: false, handle: runDatabase, row: true})
    }
    
    return (<div style={{height:'100%'}}>            
            <ModelForm {...props} model={model} loading={loading}/>
            <Checkbox style={{position: 'absolute', top: '2em', right: '3em'}} 
                    onChange={(e,{checked})=> setHiddenRow(!checked)}
                    checked={!hiddenRow}  label='Показать все' size='mini'/>
        </div>)
}

export default DatabasesForm;