/*global chrome*/
import React from 'react'
import {
  Container,
  Icon,
  Image,
  Menu,
  Segment,
  Loader, Dimmer,
  TransitionablePortal, Message,
  Label
} from 'semantic-ui-react'

import SettingForm from './setting.js'
import DashboardForm from './dashboard.js'
import {UploadProgress} from './files.js'


const ErrorBox=(props)=>{
	var { error, onHide } = props
	const animation='slide right', duration=600
	return (
		<TransitionablePortal
			open={error!==""}
            transition={{ animation, duration }}
			onHide={onHide}
          >
		  
		  <Message negative 
			style={{
                left: '0',
                position: 'fixed',
                bottom: '0',
                zIndex: 1000,
              }}>
				<Message.Header><Icon name='warning circle'/>Ошибка сервера</Message.Header>
				<p>{error}</p>
			</Message>
          </TransitionablePortal>)
}


const MainForm=(props)=>{
	
	const [winName, setWindow] = React.useState('main')
	const [statusServer, setStatusServer] = React.useState(false)
	const [errorText, setShowError] = React.useState('')
    const [uploadsStatus, changeUploadsProgress] = React.useState(0)
	const uploads = React.useRef([])
    
    props.connector.listen['checkStatus'] = (data)=>{
        if (data.server) setStatusServer(data.server.status)
        if (!data.result && data.error) setShowError(data.error)
           else setShowError('')
    }
	
    
    const changeProgress=(data)=>{
        var task = uploads.current.find(e=>e.guid===data.guid)
        if (!task) uploads.current.push(data)
            else task.status = data.status 
        uploads.current = uploads.current.filter(e=> !e.status.done) 
        changeUploadsProgress(uploadsStatus+1)        
    }
	
	const handleClickMenu=(e,{name})=> setWindow(name) 

	const isServer = props.app_type==='server'

    const runApp=(data)=>{
        //document.dispatchEvent(new CustomEvent('appEvent', {detail: data}));   
        var divId = document.getElementById('nexum-extension')        
        var ExtensionId = (divId) ? divId.getAttribute('guid') : null        
        if (!ExtensionId) {
            setShowError('Extenction not installed!!!')
            return
        }
        chrome.runtime.sendMessage(ExtensionId, data, (response) => {
            console.log(response)
            if (!response) {
                setShowError("Неизвестная ошибка")
                return
            }
            if (!response.result) setShowError(response.error)           
        })
    }

	const childProps={connector: props.connector, 
			sessionId: props.sessionId, 
			isServer: isServer,
			user:  props.user,
            client_guid:  props.client_guid,            
            runApp: runApp,
            changeProgress: changeProgress}
	
	//onContextMenu={(e)=> e.preventDefault()}
	return (<div >    
	{!props.connected && <Dimmer active inverted>  <Loader inverted size='massive'/>		</Dimmer>}
    <UploadProgress uploads={uploads.current}/>
    <Menu fixed='top' inverted>
      <Container>
        <Menu.Item header style={{ width: '15em' }} >			
			<Image size='mini' src='/64.png'/>			
			<Label circular color={statusServer || isServer ? 'green' : 'red'} empty style={{marginRight:'0.5em'}}/>
			Nexum
        </Menu.Item>
		
		<Menu.Item as='a' style={{ width: '12em' }}
			name='main' 
			active={winName==='main'} 
			onClick={handleClickMenu}>			
				<Icon name='home' size='big'/>Главное
        </Menu.Item>
        <Menu.Item  as='a'  style={{ width: '12em' }} 
				name='setting' 
				active={winName==='setting'} 
				onClick={handleClickMenu}>
			<Icon name='setting' size='big'/>Настройки		
		</Menu.Item>
		
		<Menu.Item as='a' position='right' onClick={props.onLogout}>
			<Icon name='log out' size='big'/>{props.user.login}
		</Menu.Item>		
      </Container>
    </Menu>
	
	<Container style={{paddingTop:'5em', height: `calc( ${window.innerHeight}px - 10px)`}} >
		<Segment style={{height: "100%", paddingBottom: '0'}}>
			{winName==='setting' && <SettingForm {...childProps}/>}
			{winName==='main' && <DashboardForm {...childProps}/>}
		</Segment>
	</Container>
	<ErrorBox error={errorText} onHide={()=>{setShowError('')}}/>
		</div>)
}
//style={{marginTop:'5em', }
export default MainForm;